import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
// import {
//   Create,
//   Form,
//   Input,
//   InputNumber,
//   IResourceComponentsProps,
//   Select,
//   Switch,
//   useForm,
//   useList,
//   useSelect,
// } from "@pankod/refine";

import {
  IAssociationMembership,
  IClient,
  IPricingScheme,
  IUser,
} from "../../interfaces";

export const ClientCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IClient>();

  const { selectProps: userSelect } = useSelect<IUser>({
    resource: "users",
    optionLabel: "username",
    optionValue: "id",
    sort: [{ field: "username", order: "asc" }],
  });

  const { selectProps: associationMembershipSelect } =
    useSelect<IAssociationMembership>({
      resource: "association-memberships",
      optionLabel: "name",
      optionValue: "id",
      // sort: [{ field: "username", order: "asc" }],
    });

  const { selectProps: pricingSchemesSelect } = useSelect<IPricingScheme>({
    resource: "pricing-schemes",
    optionLabel: "name",
    optionValue: "id",
  });

  const { Option } = Select;

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            user: values.user?.data.id,
            association_membership: values.association_membership?.id,
            pricing_scheme: values.pricing_scheme?.data.id,
          });
        }}
      >
        <Form.Item label="Vip" name="vip" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Type"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"ALL"}>Allevatore</Option>
            <Option key={"VET"}>Veterinario</Option>
            <Option key={"NEG"}>Negozio</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Rna" name="rna">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Tax ID" name="tax_id">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Tax Type" name="tax_type">
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"CF"}>Tax identification number</Option>
            <Option key={"PIVA"}>VAT Number</Option>
            <Option key={"NO_IT"}>No Italian</Option>
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Company Name"
          name="company_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="City" name="city">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Number"
          name="address_number"
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Province" name="province">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Zip code" name="zip_code">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Secondary Phone"
          name="secondary_phone"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Pec"
          name="pec"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Recipient Code"
          name="recipient_code"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Corporate tax code"
          name="corporate_tax_code"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="User"
          name={["user", "data", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelect} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Association membership"
          name={["association_membership", "id"]}
        >
          <Select {...associationMembershipSelect} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Fem credit"
          name="fem_credit"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Pricing Schemes"
          name={["pricing_scheme", "data", "id"]}
        >
          <Select {...pricingSchemesSelect} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Language" name="language">
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"it"}>Italian</Option>
            <Option key={"en"}>English</Option>
            <Option key={"de"}>German</Option>
            <Option key={"fr"}>French</Option>
            <Option key={"es"}>Spanish</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Country" name="country">
          <Select>
            <Option key="">---------</Option>
            <Option key="AD">Andorra</Option>
            <Option key="AE">United Arab Emirates</Option>
            <Option key="AF">Afghanistan</Option>
            <Option key="AG">Antigua &amp; Barbuda</Option>
            <Option key="AI">Anguilla</Option>
            <Option key="AL">Albania</Option>
            <Option key="AM">Armenia</Option>
            <Option key="AN">Netherlands Antilles</Option>
            <Option key="AO">Angola</Option>
            <Option key="AR">Argentina</Option>
            <Option key="AS">American Samoa</Option>
            <Option key="AT">Austria</Option>
            <Option key="AU">Australia</Option>
            <Option key="AW">Aruba</Option>
            <Option key="AZ">Azerbaijan</Option>
            <Option key="BA">Bosnia and Herzegovina</Option>
            <Option key="BB">Barbados</Option>
            <Option key="BD">Bangladesh</Option>
            <Option key="BE">Belgium</Option>
            <Option key="BF">Burkina Faso</Option>
            <Option key="BG">Bulgaria</Option>
            <Option key="BH">Bahrain</Option>
            <Option key="BI">Burundi</Option>
            <Option key="BJ">Benin</Option>
            <Option key="BM">Bermuda</Option>
            <Option key="BN">Brunei Darussalam</Option>
            <Option key="BO">Bolivia</Option>
            <Option key="BR">Brazil</Option>
            <Option key="BS">Bahama</Option>
            <Option key="BT">Bhutan</Option>
            <Option key="BV">Bouvet Island</Option>
            <Option key="BW">Botswana</Option>
            <Option key="BY">Belarus</Option>
            <Option key="BZ">Belize</Option>
            <Option key="CA">Canada</Option>
            <Option key="CC">Cocos (Keeling) Islands</Option>
            <Option key="CF">Central African Republic</Option>
            <Option key="CG">Congo</Option>
            <Option key="CH">Switzerland</Option>
            <Option key="CI">Ivory Coast</Option>
            <Option key="CK">Cook Iislands</Option>
            <Option key="CL">Chile</Option>
            <Option key="CM">Cameroon</Option>
            <Option key="CN">China</Option>
            <Option key="CO">Colombia</Option>
            <Option key="CR">Costa Rica</Option>
            <Option key="CU">Cuba</Option>
            <Option key="CV">Cape Verde</Option>
            <Option key="CX">Christmas Island</Option>
            <Option key="CY">Cyprus</Option>
            <Option key="CZ">Czech Republic</Option>
            <Option key="DE">Germany</Option>
            <Option key="DJ">Djibouti</Option>
            <Option key="DK">Denmark</Option>
            <Option key="DM">Dominica</Option>
            <Option key="DO">Dominican Republic</Option>
            <Option key="DZ">Algeria</Option>
            <Option key="EC">Ecuador</Option>
            <Option key="EE">Estonia</Option>
            <Option key="EG">Egypt</Option>
            <Option key="EH">Western Sahara</Option>
            <Option key="ER">Eritrea</Option>
            <Option key="ES">Spain</Option>
            <Option key="ET">Ethiopia</Option>
            <Option key="FI">Finland</Option>
            <Option key="FJ">Fiji</Option>
            <Option key="FK">Falkland Islands (Malvinas)</Option>
            <Option key="FM">Micronesia</Option>
            <Option key="FO">Faroe Islands</Option>
            <Option key="FR">France</Option>
            <Option key="GA">Gabon</Option>
            <Option key="GB">United Kingdom (Great Britain)</Option>
            <Option key="GD">Grenada</Option>
            <Option key="GE">Georgia</Option>
            <Option key="GF">French Guiana</Option>
            <Option key="GH">Ghana</Option>
            <Option key="GI">Gibraltar</Option>
            <Option key="GL">Greenland</Option>
            <Option key="GM">Gambia</Option>
            <Option key="GN">Guinea</Option>
            <Option key="GP">Guadeloupe</Option>
            <Option key="GQ">Equatorial Guinea</Option>
            <Option key="GR">Greece</Option>
            <Option key="GS">
              South Georgia and the South Sandwich Islands
            </Option>
            <Option key="GT">Guatemala</Option>
            <Option key="GU">Guam</Option>
            <Option key="GW">Guinea-Bissau</Option>
            <Option key="GY">Guyana</Option>
            <Option key="HK">Hong Kong</Option>
            <Option key="HM">Heard &amp; McDonald Islands</Option>
            <Option key="HN">Honduras</Option>
            <Option key="HR">Croatia</Option>
            <Option key="HT">Haiti</Option>
            <Option key="HU">Hungary</Option>
            <Option key="ID">Indonesia</Option>
            <Option key="IE">Ireland</Option>
            <Option key="IL">Israel</Option>
            <Option key="IN">India</Option>
            <Option key="IO">British Indian Ocean Territory</Option>
            <Option key="IQ">Iraq</Option>
            <Option key="IR">Islamic Republic of Iran</Option>
            <Option key="IS">Iceland</Option>
            <Option key="IT">Italy</Option>
            <Option key="JM">Jamaica</Option>
            <Option key="JO">Jordan</Option>
            <Option key="JP">Japan</Option>
            <Option key="KE">Kenya</Option>
            <Option key="KG">Kyrgyzstan</Option>
            <Option key="KH">Cambodia</Option>
            <Option key="KI">Kiribati</Option>
            <Option key="KM">Comoros</Option>
            <Option key="KN">St. Kitts and Nevis</Option>
            <Option key="KP">Korea, Democratic People's Republic of</Option>
            <Option key="KR">Korea, Republic of</Option>
            <Option key="KW">Kuwait</Option>
            <Option key="KY">Cayman Islands</Option>
            <Option key="KZ">Kazakhstan</Option>
            <Option key="LA">Lao People's Democratic Republic</Option>
            <Option key="LB">Lebanon</Option>
            <Option key="LC">Saint Lucia</Option>
            <Option key="LI">Liechtenstein</Option>
            <Option key="LK">Sri Lanka</Option>
            <Option key="LR">Liberia</Option>
            <Option key="LS">Lesotho</Option>
            <Option key="LT">Lithuania</Option>
            <Option key="LU">Luxembourg</Option>
            <Option key="LV">Latvia</Option>
            <Option key="LY">Lybia</Option>
            <Option key="MA">Morocco</Option>
            <Option key="MC">Monaco</Option>
            <Option key="MD">Moldova, Republic of</Option>
            <Option key="MG">Madagascar</Option>
            <Option key="MH">Marshall Islands</Option>
            <Option key="ML">Mali</Option>
            <Option key="MN">Mongolia</Option>
            <Option key="MM">Myanmar</Option>
            <Option key="MO">Macau</Option>
            <Option key="MP">Northern Mariana Islands</Option>
            <Option key="MQ">Martinique</Option>
            <Option key="MR">Mauritania</Option>
            <Option key="MS">Monserrat</Option>
            <Option key="MT">Malta</Option>
            <Option key="MU">Mauritius</Option>
            <Option key="MV">Maldives</Option>
            <Option key="MW">Malawi</Option>
            <Option key="MX">Mexico</Option>
            <Option key="MY">Malaysia</Option>
            <Option key="MZ">Mozambique</Option>
            <Option key="NA">Namibia</Option>
            <Option key="NC">New Caledonia</Option>
            <Option key="NE">Niger</Option>
            <Option key="NF">Norfolk Island</Option>
            <Option key="NG">Nigeria</Option>
            <Option key="NI">Nicaragua</Option>
            <Option key="NL">Netherlands</Option>
            <Option key="NO">Norway</Option>
            <Option key="NP">Nepal</Option>
            <Option key="NR">Nauru</Option>
            <Option key="NU">Niue</Option>
            <Option key="NZ">New Zealand</Option>
            <Option key="OM">Oman</Option>
            <Option key="PA">Panama</Option>
            <Option key="PE">Peru</Option>
            <Option key="PF">French Polynesia</Option>
            <Option key="PG">Papua New Guinea</Option>
            <Option key="PH">Philippines</Option>
            <Option key="PK">Pakistan</Option>
            <Option key="PL">Poland</Option>
            <Option key="PM">St. Pierre &amp; Miquelon</Option>
            <Option key="PN">Pitcairn</Option>
            <Option key="PR">Puerto Rico</Option>
            <Option key="PT">Portugal</Option>
            <Option key="PW">Palau</Option>
            <Option key="PY">Paraguay</Option>
            <Option key="QA">Qatar</Option>
            <Option key="RE">Reunion</Option>
            <Option key="RO">Romania</Option>
            <Option key="RU">Russian Federation</Option>
            <Option key="RW">Rwanda</Option>
            <Option key="SA">Saudi Arabia</Option>
            <Option key="SB">Solomon Islands</Option>
            <Option key="SC">Seychelles</Option>
            <Option key="SD">Sudan</Option>
            <Option key="SE">Sweden</Option>
            <Option key="SG">Singapore</Option>
            <Option key="SH">St. Helena</Option>
            <Option key="SI">Slovenia</Option>
            <Option key="SJ">Svalbard &amp; Jan Mayen Islands</Option>
            <Option key="SK">Slovakia</Option>
            <Option key="SL">Sierra Leone</Option>
            <Option key="SM">San Marino</Option>
            <Option key="SN">Senegal</Option>
            <Option key="SO">Somalia</Option>
            <Option key="SR">Suriname</Option>
            <Option key="ST">Sao Tome &amp; Principe</Option>
            <Option key="SV">El Salvador</Option>
            <Option key="SY">Syrian Arab Republic</Option>
            <Option key="SZ">Swaziland</Option>
            <Option key="TC">Turks &amp; Caicos Islands</Option>
            <Option key="TD">Chad</Option>
            <Option key="TF">French Southern Territories</Option>
            <Option key="TG">Togo</Option>
            <Option key="TH">Thailand</Option>
            <Option key="TJ">Tajikistan</Option>
            <Option key="TK">Tokelau</Option>
            <Option key="TM">Turkmenistan</Option>
            <Option key="TN">Tunisia</Option>
            <Option key="TO">Tonga</Option>
            <Option key="TP">East Timor</Option>
            <Option key="TR">Turkey</Option>
            <Option key="TT">Trinidad &amp; Tobago</Option>
            <Option key="TV">Tuvalu</Option>
            <Option key="TW">Taiwan, Province of China</Option>
            <Option key="TZ">Tanzania, United Republic of</Option>
            <Option key="UA">Ukraine</Option>
            <Option key="UG">Uganda</Option>
            <Option key="UM">United States Minor Outlying Islands</Option>
            <Option key="US">United States of America</Option>
            <Option key="UY">Uruguay</Option>
            <Option key="UZ">Uzbekistan</Option>
            <Option key="VA">Vatican City State (Holy See)</Option>
            <Option key="VC">St. Vincent &amp; the Grenadines</Option>
            <Option key="VE">Venezuela</Option>
            <Option key="VG">British Virgin Islands</Option>
            <Option key="VI">United States Virgin Islands</Option>
            <Option key="VN">Viet Nam</Option>
            <Option key="VU">Vanuatu</Option>
            <Option key="WF">Wallis &amp; Futuna Islands</Option>
            <Option key="WS">Samoa</Option>
            <Option key="YE">Yemen</Option>
            <Option key="YT">Mayotte</Option>
            <Option key="ZA">South Africa</Option>
            <Option key="ZM">Zambia</Option>
            <Option key="ZR">Democratic Republic of the Congo</Option>
            <Option key="ZW">Zimbabwe</Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
