import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  InputNumber,
  Select,
  useForm,
} from "@pankod/refine-antd";
// import {
//   Edit,
//   Form,
//   Input,
//   InputNumber,
//   IResourceComponentsProps,
//   Select,
//   useForm,
// } from "@pankod/refine";

export const PricingSchemeEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm({
    // metaData: { populate: ["specie"] },
  });

  const { Option } = Select;

  // const { selectProps } = useSelect<ISpecie>({
  //   resource: "species",
  //   optionLabel: "name",
  //   optionValue: "id",
  //   defaultValue: queryResult?.data?.data?.specie?.data?.id,
  //   sort: [{ field: "name", order: "asc" }],
  // });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        // onFinish={(values: any) => {
        //   return formProps.onFinish?.({
        //     ...values,
        //     specie: values.specie?.data.id,
        //   });
        // }}
      >
        <Form.Item wrapperCol={{ span: 12 }} label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Smap" name="smap">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smau" name="smau">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smar" name="smar">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Apv" name="apv">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Bfdv" name="bfdv">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Clamidia" name="clamidia">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Digital" name="digital">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Print" name="print">
          <InputNumber />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Discount applied"
          name={"discount_applied"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Option key={"CONV"}>Convenzione</Option>
            <Option key={"PACC"}>Pacchetto</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Discount threshold" name="discount_threshold">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smap convention" name="smap_convention">
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Smap discounted convention"
          name="smap_discounted_convention"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smap package 2" name="smap_package_2">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smau package 2" name="smau_package_2">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smar package 2" name="smar_package_2">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Apv sex" name="apv_sex">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Bfdv sex" name="bfdv_sex">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Apv viral" name="apv_viral">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Bfdv viral" name="bfdv_viral">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smap package 3" name="smap_package_3">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smau package 3" name="smau_package_3">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smar package 3" name="smar_package_3">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Apv package 3" name="apv_package_3">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Bfdv package 3" name="bfdv_package_3">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Order entry cost" name="order_entry_cost">
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  );
};
