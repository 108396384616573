import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  //   useModalForm,
  //   Modal,
  //   Form,
  //   Input,
  DeleteButton,
} from "@pankod/refine-antd";

import { IEmailTemplate } from "../../interfaces";
import Text from "antd/lib/typography/Text";

export const EmailTemplateList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IEmailTemplate>();

  //   const {
  //     modalProps: createModalProps,
  //     formProps: createFormProps,
  //     show: createModalShow,
  //   } = useModalForm<IEmailTemplate>({
  //     action: "create",
  //     resource: "email-templates",
  //   });

  //   const {
  //     modalProps: editModalProps,
  //     formProps: editFormProps,
  //     show: editModalShow,
  //   } = useModalForm<IEmailTemplate>({
  //     action: "edit",
  //     resource: "email-templates",
  //   });

  //   const onLoad = () => {};

  return (
    <>
      <List
      // createButtonProps={{
      //   onClick: () => {
      //     createModalShow();
      //     setSelectedTab("write");
      //   },
      // }}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="id" title="id" />
          <Table.Column
            dataIndex="name"
            title="Nome"
            render={(value) => {
              return <Text>{value}</Text>;
            }}
          />
          <Table.Column<IEmailTemplate>
            title="Actions"
            dataIndex="actions"
            render={(_text, record): React.ReactNode => {
              return (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    // onClick={() => {
                    //   editModalShow(record.id.toString());
                    //   setSelectedTab("write");
                    // }}
                  />
                  <DeleteButton
                    size="small"
                    recordItemId={record.id}
                    hideText
                  />
                </Space>
              );
            }}
          />
        </Table>
        {/* <EmailEditor
          ref={emailEditorRef}
          projectId={11713}
          onLoad={onLoad}
          options={{
            displayMode: "email",
            mergeTags: [
              {
                name: "Product_Name",
                value: "{{name}}",
              },
            ],

            projectId: 11713,
            customCSS: [
              `
              .blockbuilder-preferences, .blockbuilder-placeholder {
                font-family: "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                color: #234361;
              }

              .tab-content {
                background-color: rgb(250, 251, 255);
              }

              .icsSuz .blockbuilder-content-tool:hover:not(.disabled), .icsSuz .blockbuilder-classic-tool:hover:not(.disabled) {
                box-shadow: rgb(0 0 0 / 35%) 0px 1px 3px;
              }

              .blockbuilder-placeholder .blockbuilder-placeholder-empty {
                background: rgb(211, 245, 247);
                color: rgb(71, 77, 102);
              }

              .blockbuilder-layer.blockbuilder-layer-selected > .blockbuilder-layer-selector .blockbuilder-layer-drag, .blockbuilder-layer-controls .blockbuilder-layer-control, .cunCrG, .blockbuilder-layer-control, .blockbuilder-layer-controls .blockbuilder-layer-control:hover {
                background-color: rgb(51, 102, 255);
              }
            `,
            ],
          }}
          appearance={{
            panels: {
              tools: {
                dock: "left",
              },
            },
          }}
        /> */}
      </List>

      {/* <Modal {...createModalProps} title={"Nuovo Template"}>
        <Form {...createFormProps} layout="vertical" title={"Nuovo Template"}>
          <Form.Item
            label="Nome Template"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Contenuto" rules={[{ required: true }]}>
            <ReactMde
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            generateMarkdownPreview={(markdown) =>
                                Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                            }
                        />
          </Form.Item>
        </Form>
      </Modal> */}

      {/* <Modal {...editModalProps} title={"Modifica Template"}>
        <Form {...editFormProps} layout="vertical">
          <Form.Item
            label="Nome Template"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Contenuto" rules={[{ required: true }]}>
            <ReactMde
                            selectedTab={selectedTab}
                            onTabChange={setSelectedTab}
                            generateMarkdownPreview={(markdown) =>
                                Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
                            }
                        />
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  );
};
