import { Refine, AuthProvider, Authenticated } from "@pankod/refine-core";

import { AuthHelper } from "@pankod/refine-strapi-v4";
import { DataProvider } from "./custom/strapi-4/dataProvider";
import routerProvider from "@pankod/refine-react-router";

import axios from "axios";

import "@pankod/refine-antd/dist/styles.min.css";
import "./custom.css";

import { TOKEN_KEY, API_URL } from "./constants";
import { UserCreate, UserEdit, UserList } from "./pages/users";
import { CustomSider } from "./components";
import { ClientCreate, ClientEdit, ClientList } from "./pages/clients";
import {
  AssociationCreate,
  AssociationEdit,
  AssociationList,
} from "./pages/association";
import { EmailTemplateList } from "./pages/email_template";
import {
  AssociationMembershipCreate,
  AssociationMembershipEdit,
  AssociationMembershipList,
} from "./pages/associations_memberships";
import { Login } from "./pages/login";
import { SpecieCreate, SpecieEdit, SpecieList } from "./pages/specie";
import {
  SubSpecieCreate,
  SubSpecieEdit,
  SubSpecieList,
} from "./pages/sub_specie";
import {
  PricingSchemeCreate,
  PricingSchemeEdit,
  PricingSchemeList,
} from "./pages/pricing_scheme";
import { PackageCreate, PackageEdit, PackageList } from "./pages/package";
import { OrderEdit, OrdersList } from "./pages/orders";
import { SamplesEdit, SamplesList } from "./pages/samples";
import { Layout } from "@pankod/refine-antd";
import { AnalysisEdit, AnalysisList } from "./pages/analysis";
import {
  ExtractionMethodEdit,
  ExtractionMethodList,
} from "./pages/extraction_method";
import {
  AmplificationMethodEdit,
  AmplificationMethodList,
} from "./pages/amplification_method";
import {
  VisualizationMethodEdit,
  VisualizationMethodList,
} from "./pages/visualization_method";
import { BuyCreditEdit, BuyCreditList } from "./pages/buycredit";
import { InvoicesList } from "./pages/invoices";
import { CertificatesList } from "./pages/certificates";
import { EmailTemplateEdit } from "./pages/email_template/edit";
import { EmailTemplateCreate } from "./pages/email_template/create";
import CertificateVisor from "./pages/certificates/visor/Certificate";

const App: React.FC = () => {
  const axiosInstance = axios.create();
  const strapiAuthHelper = AuthHelper(API_URL + "/api");

  const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
      const { data, status } = await strapiAuthHelper.login(username, password);
      if (status === 200) {
        localStorage.setItem(TOKEN_KEY, data.jwt);

        // set header axios instance
        axiosInstance.defaults.headers = {
          Authorization: `Bearer ${data.jwt}`,
        };

        return Promise.resolve();
      }
      return Promise.reject();
    },
    logout: () => {
      localStorage.removeItem(TOKEN_KEY);
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        axiosInstance.defaults.headers = {
          Authorization: `Bearer ${token}`,
        };
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (!token) {
        return Promise.reject();
      }

      const { data, status } = await strapiAuthHelper.me(token);
      if (status === 200) {
        const { id, username, email } = data;
        return Promise.resolve({
          id,
          username,
          email,
        });
      }

      return Promise.reject();
    },
  };

  const AuthenticatedCertificate = () => {
    return (
      <Authenticated>
        <CertificateVisor />
      </Authenticated>
    );
  };

  return (
    <Refine
      authProvider={authProvider}
      dataProvider={DataProvider(API_URL + "/api", axiosInstance)}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            component: AuthenticatedCertificate,
            path: "/certificate/:code",
          },
        ],
      }}
      Sider={CustomSider}
      Title={({ collapsed }) => (
        <div className="logo">
          <img src="/logo.png" alt="Logo" width={collapsed ? 50 : 100} />
        </div>
      )}
      LoginPage={Login}
      Layout={Layout}
      resources={[
        {
          name: "users",
          list: UserList,
          edit: UserEdit,
          create: UserCreate,
        },
        {
          name: "clients",
          list: ClientList,
          edit: ClientEdit,
          create: ClientCreate,
        },
        {
          name: "associations",
          list: AssociationList,
          edit: AssociationEdit,
          create: AssociationCreate,
        },
        {
          name: "association-memberships",
          list: AssociationMembershipList,
          edit: AssociationMembershipEdit,
          create: AssociationMembershipCreate,
        },
        {
          name: "species",
          list: SpecieList,
          edit: SpecieEdit,
          create: SpecieCreate,
        },
        {
          name: "subspecies",
          list: SubSpecieList,
          edit: SubSpecieEdit,
          create: SubSpecieCreate,
        },
        {
          name: "pricing-schemes",
          list: PricingSchemeList,
          edit: PricingSchemeEdit,
          create: PricingSchemeCreate,
        },
        {
          name: "packages",
          list: PackageList,
          edit: PackageEdit,
          create: PackageCreate,
        },
        {
          name: "orders",
          list: OrdersList,
          edit: OrderEdit,
        },
        {
          name: "samples",
          list: SamplesList,
          edit: SamplesEdit,
        },
        {
          name: "analyses",
          list: AnalysisList,
          edit: AnalysisEdit,
        },
        {
          name: "extraction-methods",
          list: ExtractionMethodList,
          edit: ExtractionMethodEdit,
        },
        {
          name: "amplification-methods",
          list: AmplificationMethodList,
          edit: AmplificationMethodEdit,
        },
        {
          name: "visualization-methods",
          list: VisualizationMethodList,
          edit: VisualizationMethodEdit,
        },
        {
          name: "buycredits",
          list: BuyCreditList,
          edit: BuyCreditEdit,
        },
        {
          name: "email-templates",
          list: EmailTemplateList,
          edit: EmailTemplateEdit,
          create: EmailTemplateCreate,
        },
        {
          name: "invoices",
          list: InvoicesList,
        },
        {
          name: "certificates",
          list: CertificatesList,
        },
      ]}
    />
  );
};

export default App;
