import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  BooleanField,
  DeleteButton,
  EditButton,
  Icons,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
// import {
//   BooleanField,
//   DeleteButton,
//   EditButton,
//   Icons,
//   IResourceComponentsProps,
//   List,
//   Space,
//   Table,
//   useTable,
// } from "@pankod/refine";
import { IAssociationMembership } from "../../interfaces";

export const AssociationMembershipList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps } = useTable<IAssociationMembership>({
    metaData: {
      populate: ["association"],
    },
  });
  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column
          dataIndex={["association", "data", "attributes", "name"]}
          title="Association"
        />
        <Table.Column dataIndex="card" title="Card" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          title="Unofficial"
          dataIndex="unofficial"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column<IAssociationMembership>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
