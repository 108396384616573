import { useState } from "react";
import { useLogout, useNavigation, useTitle } from "@pankod/refine-core";
import { AntdLayout, Grid, Icons, Menu, useMenu } from "@pankod/refine-antd";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";

const rootSubmenuKeys = ["Utense", "Order_settings", "Orders", "Specie"];

export const CustomSider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const Title = useTitle();
  const {
    // menuItems,
    selectedKey,
  } = useMenu();
  const breakpoint = Grid.useBreakpoint();
  const { push } = useNavigation();
  const { mutate: logout } = useLogout();

  const isMobile = !breakpoint.lg;

  const { SubMenu } = Menu;
  const [openKeys, setOpenKeys] = useState(["Utense"]);

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys.find((key: any) => {
      return openKeys.indexOf(key) === -1;
    });
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <AntdLayout.Sider
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      {Title && <Title collapsed={collapsed} />}
      <Menu
        // selectedKeys={[selectedKey]}
        defaultOpenKeys={["Utense"]}
        mode="inline"
        onClick={({ key }) => {
          if (key === "logout") {
            logout();
            return;
          }
          if (!breakpoint.lg) {
            setCollapsed(true);
          }

          push(key as string);
        }}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        {/* {menuItems.map(({ icon, label, route }) => {
          console.log(route, selectedKey);
          const isSelected = route === selectedKey;
          return (
            <Menu.Item
              style={{
                fontWeight: isSelected ? "bold" : "normal",
              }}
              key={route}
              icon={icon}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {label}
                {!collapsed && isSelected && <Icons.RightOutlined />}
              </div>
            </Menu.Item>
          );
        })} */}
        <SubMenu key="Utense" title="Utense" icon={<Icons.UserOutlined />}>
          <Menu.Item
            style={{
              fontWeight: "/users" === selectedKey ? "bold" : "normal",
            }}
            key={"/users"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Users
              {!collapsed && "/users" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "/clients" === selectedKey ? "bold" : "normal",
            }}
            key={"/clients"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Clients
              {!collapsed && "/clients" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "/associations" === selectedKey ? "bold" : "normal",
            }}
            key={"/associations"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Associations
              {!collapsed && "/associations" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight:
                "/association-memberships" === selectedKey ? "bold" : "normal",
            }}
            key={"/association-memberships"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Association Memberships
              {!collapsed && "/association-memberships" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="Order_settings"
          title="Order settings"
          icon={<Icons.UserOutlined />}
        >
          <Menu.Item
            style={{
              fontWeight:
                "/pricing-schemes" === selectedKey ? "bold" : "normal",
            }}
            key={"/pricing-schemes"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Pricing Scheme
              {!collapsed && "/pricing-schemes" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "/packages" === selectedKey ? "bold" : "normal",
            }}
            key={"/packages"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Credit package
              {!collapsed && "/packages" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight:
                "/email-templates" === selectedKey ? "bold" : "normal",
            }}
            key={"/email-templates"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Email Template
              {!collapsed && "/email-templates" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="Orders" title="Orders" icon={<Icons.UserOutlined />}>
          <Menu.Item
            style={{
              fontWeight: "/orders" === selectedKey ? "bold" : "normal",
            }}
            key={"/orders"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Orders
              {!collapsed && "/orders" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "/samples" === selectedKey ? "bold" : "normal",
            }}
            key={"/samples"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Samples
              {!collapsed && "/samples" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "/buycredits" === selectedKey ? "bold" : "normal",
            }}
            key={"/buycredits"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Purchased credits
              {!collapsed && "/buycredits" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="Specie" title="Specie" icon={<Icons.UserOutlined />}>
          <Menu.Item
            style={{
              fontWeight: "/species" === selectedKey ? "bold" : "normal",
            }}
            key={"/species"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Specie
              {!collapsed && "/species" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "/subspecies" === selectedKey ? "bold" : "normal",
            }}
            key={"/subspecies"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Subspecie
              {!collapsed && "/subspecies" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="Lab" title="Lab" icon={<Icons.UserOutlined />}>
          <Menu.Item
            style={{
              fontWeight: "/analyses" === selectedKey ? "bold" : "normal",
            }}
            key={"/analyses"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Analysis
              {!collapsed && "/analyses" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight:
                "/extraction-methods" === selectedKey ? "bold" : "normal",
            }}
            key={"/extraction-methods"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Extraction method
              {!collapsed && "/extraction-methods" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight:
                "/amplification-methods" === selectedKey ? "bold" : "normal",
            }}
            key={"/amplification-methods"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Amplification method
              {!collapsed && "/amplification-methods" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight:
                "/visualization-methods" === selectedKey ? "bold" : "normal",
            }}
            key={"/visualization-methods"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Visualization method
              {!collapsed && "/visualization-methods" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
          <Menu.Item
            style={{
              fontWeight: "/samples" === selectedKey ? "bold" : "normal",
            }}
            key={"/samples"}
            icon={<Icons.UnorderedListOutlined />}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Samples
              {!collapsed && "/samples" === selectedKey && (
                <Icons.RightOutlined />
              )}
            </div>
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          style={{
            fontWeight: "/certificates" === selectedKey ? "bold" : "normal",
          }}
          key={"/certificates"}
          icon={<Icons.UnorderedListOutlined />}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Certificates Panel
            {!collapsed && "/certificates" === selectedKey && (
              <Icons.RightOutlined />
            )}
          </div>
        </Menu.Item>
        <Menu.Item
          style={{
            fontWeight: "/invoices" === selectedKey ? "bold" : "normal",
          }}
          key={"/invoices"}
          icon={<Icons.UnorderedListOutlined />}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Invoices panel
            {!collapsed && "/invoices" === selectedKey && (
              <Icons.RightOutlined />
            )}
          </div>
        </Menu.Item>
        <Menu.Item key={"logout"} icon={<Icons.LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    </AntdLayout.Sider>
  );
};
