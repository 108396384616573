import {
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { IPackage } from "../../interfaces";

export const PackageCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IPackage>();

  const { selectProps: pricingSchemeSelect } = useSelect<IPackage>({
    resource: "pricing-schemes",
    optionLabel: "name",
    optionValue: "id",
    sort: [{ field: "name", order: "asc" }],
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            pricing_scheme: values.pricing_scheme?.data.id,
          });
        }}
      >
        <Form.Item label="Price" name="price">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Credits" name="credits">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Subtitle" name="subtitle">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Pricing scheme"
          name={["pricing_scheme", "data", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...pricingSchemeSelect} />
        </Form.Item>
      </Form>
    </Create>
  );
};
