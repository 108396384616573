import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  InputNumber,
  Tabs,
  useForm,
} from "@pankod/refine-antd";
import React, { useEffect, useRef, useState } from "react";

import { IEmailTemplate } from "../../interfaces";
import EmailEditor from "react-email-editor";
const { TextArea } = Input;
const { TabPane } = Tabs;

export const EmailTemplateEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IEmailTemplate>();
  const [isEditorLoad, setIsEditorLoad] = useState(false);

  const emailEditorRef = useRef<any>(null);

  useEffect(() => {
    if (!isEditorLoad) return;
    if (queryResult?.isLoading) return;
    const templateJson = JSON.parse(queryResult?.data?.data?.design || "");
    emailEditorRef.current.editor.loadDesign(templateJson);
    setIsEditorLoad(false);
  }, [isEditorLoad, queryResult]);

  const onLoad = () => {
    setIsEditorLoad(true);
  };

  const callback = (key: any) => {
    console.log(key);
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return emailEditorRef.current.editor.exportHtml((data: any) => {
            const { design, html } = data;
            return formProps.onFinish?.({
              ...values,
              design: JSON.stringify(design),
              body_html: html,
            });
          });
        }}
      >
        <Form.Item
          wrapperCol={{ span: 8 }}
          name="template_reference_id"
          label="Riferimento id"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} name="name" label="Nome Template">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} name="subject" label="Soggetto">
          <Input />
        </Form.Item>
        <Form.Item label="Contenuto">
          <Tabs defaultActiveKey="1" onChange={callback}>
            <TabPane tab="Html" key="1">
              <EmailEditor
                ref={emailEditorRef}
                projectId={1}
                onLoad={onLoad}
                options={{
                  displayMode: "email",
                  projectId: 1,
                  customCSS: [
                    `
              .blockbuilder-preferences, .blockbuilder-placeholder {
                font-family: "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                color: #234361;
              }

              .tab-content {
                background-color: rgb(250, 251, 255);
              }

              .icsSuz .blockbuilder-content-tool:hover:not(.disabled), .icsSuz .blockbuilder-classic-tool:hover:not(.disabled) {
                box-shadow: rgb(0 0 0 / 35%) 0px 1px 3px;
              }

              .blockbuilder-placeholder .blockbuilder-placeholder-empty {
                background: rgb(211, 245, 247);
                color: rgb(71, 77, 102);
              }

              .blockbuilder-layer.blockbuilder-layer-selected > .blockbuilder-layer-selector .blockbuilder-layer-drag, .blockbuilder-layer-controls .blockbuilder-layer-control, .cunCrG, .blockbuilder-layer-control, .blockbuilder-layer-controls .blockbuilder-layer-control:hover {
                background-color: rgb(51, 102, 255);
              }
            `,
                  ],
                }}
                appearance={{
                  panels: {
                    tools: {
                      dock: "left",
                    },
                  },
                }}
              />
            </TabPane>
            <TabPane tab="Testo" key="2">
              <Form.Item name="body_text">
                <TextArea rows={12} />
              </Form.Item>
            </TabPane>
          </Tabs>
        </Form.Item>
      </Form>
    </Edit>
  );
};
