import { Icons, Image } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import styles from "./Certificate.scss";
import { API_URL } from "../../../constants";
import { useParams } from "react-router-dom";
// import { getCertificate } from '@fem/cruds/certificateCrud';
// import { Image } from 'react-bootstrap';
// import { PrinterFill } from 'react-bootstrap-icons';
// import { BASE_URL } from './../../../../@fem/cruds/constants';

// import { useParams } from "react-router-dom";

const getCertificateImage = (type) => {
  switch (type) {
    case "SMAP":
      return "sma";
    case "SMAR":
      return "sma";
    case "SMAU":
      return "sma";
    case "CLAM":
      return "clamidia";
    case "BFDV":
      return "bfdv";
    case "APV":
      return "apv";
    default:
      return "";
  }
};

const analysesResult = (type) => {
  switch (type) {
    case "POS":
      return "POSITIVO";
    case "NEG":
      return "NEGATIVO";
    case "F":
      return "FEMMINA";
    case "M":
      return "MASCHIO";
    default:
      return "";
  }
};

const europeanDate = (dateString) => {
  var date = new Date(dateString);
  return date.toLocaleDateString("it");
};

const CertificateVisor = () => {
  let { code } = useParams();
  const { data } = useOne({
    resource: "analyses",
    metaData: {
      populate: [
        "sample",
        "sample.subspecie.specie",
        "sample.order.client.certificate_logo",
      ],
    },
    id: code,
  });

  let analysesData = data?.data;
  let sampleData = analysesData?.sample?.data?.attributes;
  let specieData = sampleData?.subspecie?.data?.attributes;
  let orderData = sampleData?.order?.data?.attributes;
  let clientData = orderData?.client?.data?.attributes;

  const getSpecieName = () => {
    const specie = specieData?.specie?.data?.attributes?.name;
    const subSpecie = specieData?.name;

    if (specie !== "") {
      return `${specie}${subSpecie !== "" ? " - " : ""}${subSpecie}`;
    }
    return specieData.other_species;
  };

  return (
    <div className={"certificateExternalContainer"}>
      {data?.data && (
        <>
          <button
            className={"certificatePrintButton"}
            onClick={() => window.print()}
          >
            {/* <PrinterFill size={30} className="m-3" /> */}
            <Icons.PrinterFilled style={{ fontSize: 30 }} />
          </button>
          <div
            className={"certificateContainer"}
            style={{
              backgroundImage: `url("/certificate/${getCertificateImage(
                analysesData.analysis_type
              )}.png")`,
            }}
          >
            <div className={"certificateOwner"}>
              {sampleData.owner && sampleData.owner !== ""
                ? sampleData.owner
                : clientData.company_name}
            </div>
            <div className={"certificateSubject"}>{sampleData.identifier}</div>
            <div className={"certificateSpecie"}>{getSpecieName()}</div>
            <div className={"certificateMutation"}>
              {sampleData.mutation === "" ? "/" : sampleData.mutation}
            </div>
            <div className={"certificateBorn"}>
              {sampleData.date_of_birth
                ? europeanDate(sampleData.date_of_birth)
                : "/"}
            </div>
            <div className={"certificateDate"}>
              {europeanDate(analysesData.updatedAt)}
            </div>
            <div className={"certificateLogo"}>
              {clientData.certificate_logo.data ? (
                <Image
                  className={"certificateLogoImage"}
                  src={
                    API_URL + clientData.certificate_logo.data.attributes.url
                  }
                />
              ) : null}
            </div>
            <div className={"certificateResult"}>
              {analysesResult(analysesData.result)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CertificateVisor;
