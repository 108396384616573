import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  Button,
  Col,
  Collapse,
  DeleteButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Row,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { ISample, ISampleFilterVariables } from "../../interfaces";
import { openInNewTab } from "../../functions";

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { Panel } = Collapse;

  return (
    <Collapse style={{ marginBottom: 10 }} defaultActiveKey={["1"]}>
      <Panel
        header={
          <>
            <Icons.FilterOutlined style={{ marginRight: 10 }} />
            Filters
          </>
        }
        key="1"
        showArrow={false}
      >
        <Form layout="vertical" {...formProps}>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Search" name="q">
                <Input
                  placeholder="ID, Identifier, etc."
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Order" name="order">
                <Input
                  placeholder="Search Order."
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Subspecie" name="subspecie">
                <Input
                  placeholder="Search Subspecie."
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row> */}
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

export const SamplesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    ISample,
    HttpError,
    ISampleFilterVariables
  >({
    permanentSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["order", "subspecie.specie"],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, order } = params;

      filters.push(
        {
          field: "_q",
          operator: "eq",
          value: q || "",
        },
        {
          field: "order.id",
          operator: "eq",
          value: order,
        }
        // {
        //   field: "subspecie.id",
        //   operator: "eq",
        //   value: subspecie,
        // }
      );

      return filters;
    },
  });

  return (
    <List>
      <Filter formProps={searchFormProps} />
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column dataIndex="id" title="ID" /> */}
        <Table.Column
          dataIndex={["order", "data", "id"]}
          title="Order"
          render={(value) => {
            return `#${value}`;
          }}
        />
        <Table.Column dataIndex="identifier" title="Identifier" />
        <Table.Column
          dataIndex="subspecie"
          title="Subspecie"
          render={(value) => {
            if (!value.data) return "(Nada)";
            return `${
              value?.data?.attributes?.specie?.data?.attributes?.name
            } ${
              value.data.attributes.name
                ? ` - ${value.data.attributes.name}`
                : ""
            }`;
          }}
        />
        <Table.Column
          dataIndex={"standard"}
          title="Standard"
          render={(value) => {
            if (!value) return "(Nada)";
            return value;
          }}
        />
        <Table.Column<ISample>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Button
                size="small"
                onClick={() => {
                  openInNewTab(`/samples/edit/${record.id}`);
                }}
                className={"ant-btn-icon-only"}
              >
                <Icons.EditOutlined />
              </Button>
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
