import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";
import {
  Icons,
  List,
  NumberField,
  Popconfirm,
  Table,
  useTable,
} from "@pankod/refine-antd";
import RouterProvider from "@pankod/refine-react-router";
import { IOrder } from "../../interfaces";

export const InvoicesList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps: invoiceClosed, tableQueryResult } = useTable<IOrder>({
    resource: "orders/close",
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["client", "samples.analyses"],
    },
  });

  const { tableProps: invoiceOpen } = useTable<IOrder>({
    resource: "orders/open",
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["client", "samples.analyses"],
    },
  });

  const Link = RouterProvider.Link;
  const { CloseCircleOutlined, CheckCircleOutlined, CheckSquareOutlined } =
    Icons;

  const { mutate, isLoading } = useUpdate();
  return (
    <>
      <List title="ORDINI CHIUSI con Fattura/Ricevuta Fiscale da emettere">
        <Table {...invoiceClosed} rowKey="id">
          <Table.Column dataIndex="id" title="Nº ORD" />
          <Table.Column
            dataIndex={["client", "data", "attributes", "company_name"]}
            title="NOME COGNOME RAGIONE SOCIALE"
          />
          <Table.Column
            dataIndex={["client", "data", "attributes", "tax_id"]}
            title="CODICE FISCALE PARTITA IVA"
          />
          {/* <Table.Column
            dataIndex={["client", "data", "attributes", "type"]}
            title="TIPO CLIENTE"
            render={(value) => {
              switch (value) {
                case "AMAT":
                  return "Amatore";
                case "ALL":
                  return "Allevatore";
                case "VET":
                  return "Veterinario";
                case "NEG":
                  return "Negozio";
                default:
                  return "";
              }
            }}
          /> */}
          <Table.Column
            dataIndex={["client", "data", "attributes"]}
            title="INDIRIZZO"
            render={(value) => {
              return `${value.city} ${value.address} ${value.address_number} ${value.province} ${value.zip_code} `;
            }}
          />
          <Table.Column
            dataIndex={["client", "data", "attributes"]}
            title="EMAIL TELEFONO"
            render={(value) => {
              return (
                <div>
                  <div>{value.email}</div>
                  <div>{value.phone}</div>
                  <div>{value.secondary_phone}</div>
                </div>
              );
            }}
          />
          {/* <Table.Column
            dataIndex={["client", "data", "attributes", "pec"]}
            title="PEC"
          /> */}
          {/* <Table.Column
            dataIndex={["client", "data", "attributes", "recipient_code"]}
            title="CODICE SDI"
          /> */}
          <Table.Column
            dataIndex={["client", "data", "attributes", "corporate_tax_code"]}
            title="CODICE FISCALE AZIENDALE"
          />
          <Table.Column
            dataIndex={"total_services"}
            title="TOTALE"
            render={(value) => {
              return (
                <>
                  <span>€</span>
                  <NumberField
                    value={value || 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={"credits_consumed"}
            title="CREDITI UTILIZZATI"
            render={(value) => {
              return (
                <>
                  <span>€</span>
                  <NumberField
                    value={value || 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={""}
            title="AMMONTARE DA EMETTERE"
            render={(value) => {
              const total =
                value.total_services - (value.credits_consumed || 0);
              return (
                <>
                  <span>€</span>
                  <NumberField
                    value={total || 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={"require_invoice"}
            title="RICHIEDE FATTURA"
            render={(value) => {
              return value ? (
                <CheckCircleOutlined
                  style={{ fontSize: "16px", color: "#52c41a" }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ fontSize: "16px", color: "#eb2f96" }}
                />
              );
            }}
          />
          <Table.Column
            dataIndex={"is_new"}
            title="NUOVO CLIENTE"
            render={(value) => {
              return value ? (
                <CheckCircleOutlined
                  style={{ fontSize: "16px", color: "#52c41a" }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ fontSize: "16px", color: "#eb2f96" }}
                />
              );
            }}
          />
          <Table.Column
            dataIndex={"details"}
            title="DETTAGLI"
            render={(value) => {
              return (
                <div style={{ width: 170 }}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Spedizione:</span>{" "}
                    {
                      <NumberField
                        value={value.shipping || 0}
                        options={{
                          notation: "standard",
                        }}
                      />
                    }{" "}
                    €
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Commissione:</span>{" "}
                    {
                      <NumberField
                        value={value.commission || 0}
                        options={{
                          notation: "standard",
                        }}
                      />
                    }{" "}
                    €
                  </div>
                  <div style={{ fontWeight: "bold" }}>Analisi</div>
                  {value.analyses.map((item: any, key: string) => {
                    return (
                      <div key={`analisi-${key}`}>
                        {item.title} ({item.total}) {" - "}
                        {
                          <NumberField
                            value={item.price || 0}
                            options={{
                              notation: "standard",
                            }}
                          />
                        }{" "}
                        €
                      </div>
                    );
                  })}
                  <div style={{ fontWeight: "bold" }}>Certificati cartaceo</div>
                  {value.print.map((item: any, key: string) => {
                    return (
                      <div key={`certificati-cartaceo-${key}`}>
                        {item.title} ({item.total}) {" - "}
                        {
                          <NumberField
                            value={item.price || 0}
                            options={{
                              notation: "standard",
                            }}
                          />
                        }{" "}
                        €
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
          <Table.Column
            dataIndex={["payment_method"]}
            title="Metodo di pagamento"
            render={(value) => {
              switch (value) {
                case "PPAL":
                  return "PayPal";
                case "BONB":
                  return "Bank Trasfer";
                case "BOLP":
                  return "Bollettino postale";
                case "CARTA":
                  return "Credit Card";
                case "CREDITO":
                  return "FEM Credit";
                default:
                  return "";
              }
            }}
          />
          <Table.Column
            dataIndex={["payment_details"]}
            title="DETTAGLI DEL PAGAMENTO"
          />
          <Table.Column
            dataIndex={"id"}
            title=""
            render={(value) => {
              return (
                <div style={{ width: 60 }}>
                  {/* <Popconfirm
                    key="delete"
                    okText={"Accettare"}
                    cancelText={"Cancella"}
                    okType="danger"
                    title={"Sei sicuro?"}
                    okButtonProps={{ disabled: isLoading }}
                    onConfirm={(): void => {
                      mutate(
                        {
                          resource: "orders",
                          id: value,
                          values: { status: "CHIUSO" },
                        },
                        {
                          onSuccess: () => {
                            tableQueryResult.refetch();
                          },
                        }
                      );
                    }}
                  > */}
                  <Link
                    to="#"
                    onClick={(): void => {
                      mutate(
                        {
                          resource: "orders",
                          id: value,
                          values: { status: "CHIUSO" },
                        },
                        {
                          onSuccess: () => {
                            tableQueryResult.refetch();
                          },
                        }
                      );
                    }}
                  >
                    <CheckSquareOutlined
                      style={{ fontSize: "32px", color: "#52c41a" }}
                    />
                  </Link>
                  {/* </Popconfirm> */}
                </div>
              );
            }}
          />
        </Table>
      </List>
      <List title="ORDINI APERTI con Fattura/Ricevuta Fiscale da emettere">
        <Table {...invoiceOpen} rowKey="id">
          <Table.Column dataIndex="id" title="Nº ORD" />
          <Table.Column
            dataIndex={["client", "data", "attributes", "company_name"]}
            title="NOME COGNOME RAGIONE SOCIALE"
          />
          <Table.Column
            dataIndex={["client", "data", "attributes", "tax_id"]}
            title="CODICE FISCALE PARTITA IVA"
          />
          {/* <Table.Column
            dataIndex={["client", "data", "attributes", "type"]}
            title="TIPO CLIENTE"
            render={(value) => {
              switch (value) {
                case "AMAT":
                  return "Amatore";
                case "ALL":
                  return "Allevatore";
                case "VET":
                  return "Veterinario";
                case "NEG":
                  return "Negozio";
                default:
                  return "";
              }
            }}
          /> */}
          <Table.Column
            dataIndex={["client", "data", "attributes"]}
            title="INDIRIZZO"
            render={(value) => {
              return `${value.city} ${value.address} ${value.address_number} ${value.province} ${value.zip_code} `;
            }}
          />
          <Table.Column
            dataIndex={["client", "data", "attributes"]}
            title="EMAIL TELEFONO"
            render={(value) => {
              return (
                <div>
                  <div>{value.email}</div>
                  <div>{value.phone}</div>
                  <div>{value.secondary_phone}</div>
                </div>
              );
            }}
          />
          {/* <Table.Column
            dataIndex={["client", "data", "attributes", "pec"]}
            title="PEC"
          />
          <Table.Column
            dataIndex={["client", "data", "attributes", "recipient_code"]}
            title="CODICE SDI"
          /> */}
          <Table.Column
            dataIndex={["client", "data", "attributes", "corporate_tax_code"]}
            title="CODICE FISCALE AZIENDALE"
          />
          <Table.Column
            dataIndex={"total_services"}
            title="TOTALE"
            render={(value) => {
              return (
                <>
                  <span>€</span>
                  <NumberField
                    value={value || 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={"credits_consumed"}
            title="CREDITI UTILIZZATI"
            render={(value) => {
              return (
                <>
                  <span>€</span>
                  <NumberField
                    value={value || 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={""}
            title="AMMONTARE DA EMETTERE"
            render={(value) => {
              const total =
                value.total_services - (value.credits_consumed || 0);
              return (
                <>
                  <span>€</span>
                  <NumberField
                    value={total || 0}
                    options={{
                      notation: "standard",
                    }}
                  />
                </>
              );
            }}
          />
          <Table.Column
            dataIndex={"require_invoice"}
            title="RICHIEDE FATTURA"
            render={(value) => {
              return value ? (
                <CheckCircleOutlined
                  style={{ fontSize: "16px", color: "#52c41a" }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ fontSize: "16px", color: "#eb2f96" }}
                />
              );
            }}
          />
          <Table.Column
            dataIndex={"is_new"}
            title="NUOVO CLIENTE"
            render={(value) => {
              return value ? (
                <CheckCircleOutlined
                  style={{ fontSize: "16px", color: "#52c41a" }}
                />
              ) : (
                <CloseCircleOutlined
                  style={{ fontSize: "16px", color: "#eb2f96" }}
                />
              );
            }}
          />
          <Table.Column
            dataIndex={"details"}
            title="DETTAGLI"
            render={(value) => {
              return (
                <div style={{ width: 170 }}>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Spedizione:</span>{" "}
                    {
                      <NumberField
                        value={value.shipping || 0}
                        options={{
                          notation: "standard",
                        }}
                      />
                    }{" "}
                    €
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Commissione:</span>{" "}
                    {
                      <NumberField
                        value={value.commission || 0}
                        options={{
                          notation: "standard",
                        }}
                      />
                    }{" "}
                    €
                  </div>
                  <div style={{ fontWeight: "bold" }}>Analisi</div>
                  {value.analyses.map((item: any, key: string) => {
                    return (
                      <div key={`analisi-two-${key}`}>
                        {item.title} ({item.total}) {" - "}
                        {
                          <NumberField
                            value={item.price || 0}
                            options={{
                              notation: "standard",
                            }}
                          />
                        }{" "}
                        €
                      </div>
                    );
                  })}
                  <div style={{ fontWeight: "bold" }}>Certificati cartaceo</div>
                  {value.print.map((item: any, key: string) => {
                    return (
                      <div key={`certificati-cartaceo-two-${key}`}>
                        {item.title} ({item.total}) {" - "}
                        {
                          <NumberField
                            value={item.price || 0}
                            options={{
                              notation: "standard",
                            }}
                          />
                        }{" "}
                        €
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
          <Table.Column
            dataIndex={["payment_method"]}
            title="Metodo di pagamento"
            render={(value) => {
              switch (value) {
                case "PPAL":
                  return "PayPal";
                case "BONB":
                  return "Bank Trasfer";
                case "BOLP":
                  return "Bollettino postale";
                case "CARTA":
                  return "Credit Card";
                case "CREDITO":
                  return "FEM Credit";
                default:
                  return "";
              }
            }}
          />
          <Table.Column
            dataIndex={["payment_details"]}
            title="DETTAGLI DEL PAGAMENTO"
          />
        </Table>
      </List>
    </>
  );
};
