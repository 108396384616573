import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
// import {
//   Create,
//   Form,
//   Input,
//   InputNumber,
//   IResourceComponentsProps,
//   Select,
//   Switch,
//   useForm,
//   useSelect,
// } from "@pankod/refine";

import {
  ISubSpecie,
  ISpecie,
  IExtractionMethod,
  IAmplificationMethod,
  IVisualizationMethod,
} from "../../interfaces";

export const SubSpecieCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ISubSpecie>();

  const { selectProps } = useSelect<ISpecie>({
    resource: "species",
    optionLabel: "name",
    optionValue: "id",
    sort: [{ field: "name", order: "asc" }],
  });

  const { selectProps: selectSexExtractionMethodsProps } =
    useSelect<IExtractionMethod>({
      resource: "extraction-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectSexAmplificactionMethodsProps } =
    useSelect<IAmplificationMethod>({
      resource: "amplification-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectSexVisualizationMethodsProps } =
    useSelect<IVisualizationMethod>({
      resource: "visualization-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectVisExtractionMethodsProps } =
    useSelect<IExtractionMethod>({
      resource: "extraction-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectVisAmplificactionMethodsProps } =
    useSelect<IAmplificationMethod>({
      resource: "amplification-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectVisVisualizationMethodsProps } =
    useSelect<IVisualizationMethod>({
      resource: "visualization-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectOtherExtractionMethodsProps } =
    useSelect<IExtractionMethod>({
      resource: "extraction-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectOtherAmplificactionMethodsProps } =
    useSelect<IAmplificationMethod>({
      resource: "amplification-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  const { selectProps: selectOtherVisualizationMethodsProps } =
    useSelect<IVisualizationMethod>({
      resource: "visualization-methods",
      optionLabel: "name",
      optionValue: "id",
      sort: [{ field: "name", order: "asc" }],
    });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            specie: values.specie,
            other_amplification_method:
              values.other_amplification_method.data.id,
            other_extraction_method: values.other_extraction_method.data.id,
            other_visualization_method:
              values.other_visualization_method.data.id,
            sex_amplification_method: values.sex_amplification_method.data.id,
            sex_extraction_method: values.sex_extraction_method.data.id,
            sex_visualization_method: values.sex_visualization_method.data.id,
            vir_amplification_method: values.vir_amplification_method.data.id,
            vir_extraction_method: values.vir_extraction_method.data.id,
            vir_visualization_method: values.vir_visualization_method.data.id,
          });
        }}
      >
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Specie"
          name={"specie"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 14 }} label="Subspecie" name="name">
          <Input />
        </Form.Item>
        <Form.Item
          label="Common name"
          name="common_names"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Protonyms"
          name="protonyms"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Enabled" name="enabled" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Junior" name="junior" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          label="Plumage m"
          name="plumage_m"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Plumage_f"
          name="plumage_f"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Threshold plume m"
          name="threshold_plume_m"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          label="Threshold plume f"
          name="threshold_plume_f"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Sex extraction method"
          name={["sex_extraction_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectSexExtractionMethodsProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Sex amplification method"
          name={["sex_amplification_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectSexAmplificactionMethodsProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Sex visualization method"
          name={["sex_visualization_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectSexVisualizationMethodsProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Vis extraction method"
          name={["vir_extraction_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectVisExtractionMethodsProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Vis amplification method"
          name={["vir_amplification_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectVisAmplificactionMethodsProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Vis visualization method"
          name={["vir_visualization_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectVisVisualizationMethodsProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Other extraction method"
          name={["other_extraction_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectOtherExtractionMethodsProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Other amplification method"
          name={["other_amplification_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectOtherAmplificactionMethodsProps} />
        </Form.Item>

        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Other visualization method"
          name={["other_visualization_method", "data", "id"]}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <Select {...selectOtherVisualizationMethodsProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
