import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useExport,
  useUpdate,
} from "@pankod/refine-core";
import {
  Button,
  Col,
  Collapse,
  DeleteButton,
  EditButton,
  ExportButton,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Row,
  Select,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import {
  IAnalysis,
  IAnalysisFilterVariables,
  ISubSpecie,
} from "../../interfaces";
import { openInNewTab } from "../../functions";

const { CloseCircleOutlined, CheckCircleOutlined } = Icons;
const { Option } = Select;

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { Panel } = Collapse;
  const { Option } = Select;

  return (
    <Collapse style={{ marginBottom: 10 }} defaultActiveKey={["1"]}>
      <Panel
        header={
          <>
            <Icons.FilterOutlined style={{ marginRight: 10 }} />
            Filters
          </>
        }
        key="1"
        showArrow={false}
      >
        {console.log("formProps", formProps)}
        <Form layout="vertical" {...formProps}>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Search" name="q">
                <Input
                  placeholder="ID, Prices, etc."
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="List Lab Id" name="listLabId">
                <Select
                  placeholder="List Lab Id"
                  style={{ width: "100%" }}
                  allowClear
                  defaultValue={"all"}
                >
                  <Option key={"all"}>All</Option>
                  <Option key={"only-with-lab-id"}>Only with lab id</Option>
                  <Option key={"only-without-lab-id"}>
                    Only without lab id
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Order Id" name="orderId">
                <Input
                  placeholder="Order Id"
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Lab Id" name="labId">
                <Input
                  placeholder="Lab Id"
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

export const AnalysisList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IAnalysis,
    HttpError,
    IAnalysisFilterVariables
  >({
    // resource: "analysis/dashboard",
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: [
        "sample.lab",
        "sample.order",
        "extraction_method",
        "amplification_method",
        "visualization_method",
      ],
    },
    onSearch: (params) => {
      let filters: CrudFilters = [];
      const { q, listLabId, orderId, labId } = params;

      filters.push({
        field: "_q",
        operator: "eq",
        value: q && q !== "" ? q : undefined,
      });

      filters.push({
        field: "sample.order.id",
        operator: "eq",
        value: orderId && orderId !== "" ? orderId : undefined,
      });

      filters.push({
        field: "sample.lab.id",
        operator: "eq",
        value: labId && labId !== "" ? labId : undefined,
      });

      if (listLabId !== "all") {
        if (listLabId === "only-with-lab-id") {
          filters.push({
            field: "sample.lab.id",
            operator: "null",
            value: 1,
          });
        } else if (listLabId === "only-without-lab-id") {
          filters.push({
            field: "sample.lab.id",
            operator: "null",
            value: 0,
          });
        }
      } else {
        filters.push({
          field: "sample.lab.id",
          operator: "null",
          value: undefined,
        });
      }

      return filters;
    },
  });

  const getSpecie = (data: ISubSpecie, other_species: string) => {
    const subspecie = data?.attributes?.name || "";
    const specie = data?.attributes?.specie?.data?.attributes?.name || "";
    if (subspecie === "" && specie === "" && other_species !== "") {
      return other_species;
    }
    return `${specie}${subspecie !== "" ? " - " : ""}${subspecie}`;
  };

  const { triggerExport, isLoading } = useExport<IAnalysis>({
    resourceName: "analysis/export",
    pageSize: 10000,
    sorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    metaData: {
      populate: [
        "sample.lab",
        "sample.order.client",
        "sample.subspecie.specie",
        "extraction_method",
        "amplification_method",
        "visualization_method",
      ],
    },
    // filters: [
    //   {
    //     field: "result",
    //     operator: "eq",
    //     value: "",
    //   },
    //   {
    //     field: "sample.lab.id",
    //     operator: "ne",
    //     value: "",
    //   },
    // ],
    mapData: (item) => {
      return {
        "Order ID": item?.sample?.data?.attributes?.order?.data?.id || "",
        "Analisys ID": item?.id,
        "Analysis type": item?.analysis_type || "",
        Sample: item?.sample?.data?.attributes?.identifier || "",
        Client:
          item?.sample?.data?.attributes?.order?.data?.attributes?.client?.data
            ?.attributes?.company_name || "",
        Subspecie:
          getSpecie(
            item?.sample?.data?.attributes?.subspecie?.data,
            item?.sample?.data?.attributes?.other_species
          ) || "",
        "Extraction method":
          item?.extraction_method?.data?.attributes?.name || "",
        "Amplification method	":
          item?.amplification_method?.data?.attributes?.name || "",
        "Visualization method":
          item?.visualization_method?.data?.attributes?.name || "",
        "Lab ID": item?.sample?.data?.attributes?.lab?.data?.id || "",
        "Standard analysis": item?.standard_analysis ? "Yes" : "No",
      };
    },
  });

  const { mutate } = useUpdate();

  const updateResult = (id: string, value: string) => {
    // console.log(id);
    // console.log(value);
    mutate({
      resource: "analyses",
      id: id,
      values: { result: value },
    });
  };

  return (
    <List
      pageHeaderProps={{
        extra: <ExportButton onClick={triggerExport} loading={isLoading} />,
      }}
    >
      <Filter formProps={searchFormProps} />
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={["sample", "data", "attributes", "order", "data", "id"]}
          title="Order ID"
        />
        <Table.Column dataIndex="id" title="Analisys ID" />
        <Table.Column dataIndex="analysis_type" title="Analysis type" />
        <Table.Column
          dataIndex={["sample", "data", "attributes", "identifier"]}
          title="Sample"
        />
        <Table.Column
          dataIndex={["extraction_method", "data", "attributes", "name"]}
          title="Extraction method"
        />
        <Table.Column
          dataIndex={["amplification_method", "data", "attributes", "name"]}
          title="Amplification method"
        />
        <Table.Column
          dataIndex={["visualization_method", "data", "attributes", "name"]}
          title="Visualization method"
        />
        <Table.Column
          dataIndex={""}
          title="Result"
          render={(value) => {
            return (
              <Select
                style={{ minWidth: 200 }}
                defaultValue={value.result}
                onChange={(selectValue) => updateResult(value.id, selectValue)}
              >
                <Option key={""}>---------</Option>
                {value.analysis_type === "APV" ||
                value.analysis_type === "CLAM" ||
                value.analysis_type === "BFDV" ? (
                  <>
                    <Option key={"POS"}>Positivo</Option>
                    <Option key={"NEG"}>Negativo</Option>
                  </>
                ) : (
                  <>
                    <Option key={"M"}>Maschio</Option>
                    <Option key={"F"}>Femmina</Option>
                  </>
                )}

                <Option key={"FAIL"}>Inconclusivo</Option>
              </Select>
            );
          }}
        />
        <Table.Column
          dataIndex={["sample", "data", "attributes", "lab", "data", "id"]}
          title="Lab ID"
        />
        <Table.Column
          dataIndex={["standard_analysis"]}
          title="Standard analysis"
          render={(value) => {
            return value ? (
              <CheckCircleOutlined
                style={{ fontSize: "16px", color: "#52c41a" }}
              />
            ) : (
              <CloseCircleOutlined
                style={{ fontSize: "16px", color: "#eb2f96" }}
              />
            );
          }}
        />
        {/* 
        <Table.Column dataIndex="identifier" title="Identifier" />
        <Table.Column
          dataIndex="subspecie"
          title="Subspecie"
          render={(value) => {
            if (!value.data) return "(Nada)";
            return `${
              value?.data?.attributes?.specie?.data?.attributes?.name
            } ${
              value.data.attributes.name
                ? ` - ${value.data.attributes.name}`
                : ""
            }`;
          }}
        />
        <Table.Column
          dataIndex={"standard"}
          title="Standard"
          render={(value) => {
            if (!value) return "(Nada)";
            return value;
          }}
        />
         */}
        <Table.Column<IAnalysis>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Button
                size="small"
                onClick={() => {
                  openInNewTab(`/analyses/edit/${record.id}`);
                }}
                className={"ant-btn-icon-only"}
              >
                <Icons.EditOutlined />
              </Button>
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
