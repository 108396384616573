import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import React from "react";

import { IAnalysis } from "../../interfaces";
const { TextArea } = Input;

export const AnalysisEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IAnalysis>({
    metaData: {
      populate: [
        "sample.order",
        "sample.lab",
        "extraction_method",
        "amplification_method",
        "visualization_method",
      ],
    },
    redirect: false,
  });

  const { selectProps: extractionMethodSelect } = useSelect<IAnalysis>({
    resource: "extraction-methods",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.extraction_method?.data?.id,
    // sort: [{ field: "company_name", order: "asc" }],
  });

  const { selectProps: amplificationMethodSelect } = useSelect<IAnalysis>({
    resource: "amplification-methods",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.amplification_method?.data?.id,
  });

  const { selectProps: visualizationMethodSelect } = useSelect<IAnalysis>({
    resource: "visualization-methods",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.visualization_method?.data?.id,
  });

  const { Option } = Select;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            amplification_method: values.amplification_method?.data?.id,
            extraction_method: values.extraction_method?.data?.id,
            visualization_method: values.visualization_method?.data?.id,
          });
        }}
      >
        <Form.Item wrapperCol={{ span: 8 }} label="Order">
          {queryResult?.data?.data?.sample?.data?.attributes?.order?.data?.id}
          <span style={{ marginLeft: 40, marginRight: 10 }}>Lab ID:</span>
          {queryResult?.data?.data?.sample?.data?.attributes?.lab?.data?.id}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Analysis type">
          {queryResult?.data?.data?.analysis_type}
          <span style={{ marginLeft: 40, marginRight: 10 }}>Sample:</span>
          {queryResult?.data?.data?.sample?.data?.attributes?.identifier}
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Extraction method"
          name={["extraction_method", "data", "id"]}
        >
          <Select {...extractionMethodSelect} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Amplification method"
          name={["amplification_method", "data", "id"]}
        >
          <Select {...amplificationMethodSelect} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Visualization method"
          name={["visualization_method", "data", "id"]}
        >
          <Select {...visualizationMethodSelect} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Notes result"
          name="notes_result"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Result" name="result">
          <Select>
            <Option key={""}>---------</Option>
            {queryResult?.data?.data?.analysis_type === "APV" ||
            queryResult?.data?.data?.analysis_type === "CLAM" ||
            queryResult?.data?.data?.analysis_type === "BFDV" ? (
              <>
                <Option key={"POS"}>Positivo</Option>
                <Option key={"NEG"}>Negativo</Option>
              </>
            ) : (
              <>
                <Option key={"M"}>Maschio</Option>
                <Option key={"F"}>Femmina</Option>
              </>
            )}

            <Option key={"FAIL"}>Inconclusivo</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Certificate paper"
          name="certificate_paper"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label="Certificate digital"
          name="certificate_digital"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="PRICE">
          {queryResult?.data?.data?.price}
        </Form.Item>
      </Form>
    </Edit>
  );
};
