import { IResourceComponentsProps, useList } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
// import {
//   Create,
//   Form,
//   Input,
//   InputNumber,
//   IResourceComponentsProps,
//   Select,
//   Switch,
//   useForm,
//   useList,
//   useSelect,
// } from "@pankod/refine";

import {
  IAssociationMembership,
  IClient,
  IPricingScheme,
  IUser,
} from "../../interfaces";
import CountrySelect from "../../components/countrySelect";

export const ClientCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IClient>();

  const { selectProps: userSelect } = useSelect<IUser>({
    resource: "users",
    optionLabel: "username",
    optionValue: "id",
    sort: [{ field: "username", order: "asc" }],
  });

  const associationMembershipSelect = useList<IAssociationMembership>({
    resource: "association-memberships",
  });

  const { selectProps: pricingSchemesSelect } = useSelect<IPricingScheme>({
    resource: "pricing-schemes",
    optionLabel: "name",
    optionValue: "id",
  });

  const { Option } = Select;

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            user: values.user?.data.id,
            association_membership: values.association_membership?.id,
            pricing_scheme: values.pricing_scheme?.data.id,
          });
        }}
      >
        <Form.Item label="Vip" name="vip" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Type"
          name="type"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"ALL"}>Allevatore</Option>
            <Option key={"VET"}>Veterinario</Option>
            <Option key={"NEG"}>Negozio</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Rna" name="rna">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Tax ID" name="tax_id">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Tax Type" name="tax_type">
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"CF"}>Tax identification number</Option>
            <Option key={"PIVA"}>VAT Number</Option>
            <Option key={"NO_IT"}>No Italian</Option>
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Company Name"
          name="company_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="City" name="city">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Number"
          name="address_number"
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Province" name="province">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Zip code" name="zip_code">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Secondary Phone"
          name="secondary_phone"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Pec"
          name="pec"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Recipient Code"
          name="recipient_code"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Corporate tax code"
          name="corporate_tax_code"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="User"
          name={["user", "data", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...userSelect} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Association membership"
          name={["association_membership", "id"]}
        >
          <Select>
            {(associationMembershipSelect?.data?.data || []).map(
              (associationMember: IAssociationMembership) => {
                return (
                  <Option key={associationMember.id}>
                    {associationMember.name} - {associationMember.card}
                  </Option>
                );
              }
            )}
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Fem credit"
          name="fem_credit"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Pricing Schemes"
          name={["pricing_scheme", "data", "id"]}
        >
          <Select {...pricingSchemesSelect} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Language" name="language">
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"it"}>Italian</Option>
            <Option key={"en"}>English</Option>
            <Option key={"de"}>German</Option>
            <Option key={"fr"}>French</Option>
            <Option key={"es"}>Spanish</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Country" name="country">
          <CountrySelect />
        </Form.Item>
      </Form>
    </Create>
  );
};
