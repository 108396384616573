import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
// import {
//   DeleteButton,
//   EditButton,
//   IResourceComponentsProps,
//   List,
//   Space,
//   Table,
//   useTable,
// } from "@pankod/refine";
import { IPackage } from "../../interfaces";

export const PackageList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IPackage>({
    initialSorter: [
      {
        field: "price",
        order: "asc",
      },
    ],
    metaData: {
      populate: ["pricing_scheme"],
    },
  });
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex={"price"} title="Price" />
        <Table.Column dataIndex={"credits"} title="Credits" />
        <Table.Column dataIndex={"title"} title="Title" />
        <Table.Column dataIndex={"subtitle"} title="Subtitle" />
        <Table.Column
          dataIndex={["pricing_scheme", "data", "attributes", "name"]}
          title="Pricing scheme"
        />
        <Table.Column<IPackage>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* <ShowButton size="small" hideText recordItemId={record.id} /> */}
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
