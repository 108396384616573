import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  InputNumber,
  Select,
  useForm,
} from "@pankod/refine-antd";
// import {
//   Create,
//   Form,
//   Input,
//   InputNumber,
//   IResourceComponentsProps,
//   Select,
//   useForm,
// } from "@pankod/refine";

export const PricingSchemeCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();
  const { Option } = Select;

  // const { selectProps } = useSelect<ISpecie>({
  //   resource: "species",
  //   optionLabel: "name",
  //   optionValue: "id",
  //   sort: [{ field: "name", order: "asc" }],
  // });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item wrapperCol={{ span: 12 }} label="Name" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Smap" name="smap">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smau" name="smau">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Smar" name="smar">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Apv" name="apv">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Bfdv" name="bfdv">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Clamidia" name="clamidia">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Digital" name="digital">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Print" name="print">
          <InputNumber />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Discount applied"
          name={"discount_applied"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Option key={"CONV"}>Convenzione</Option>
            <Option key={"PACC"}>Pacchetto</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Discount_threshold" name="discount_threshold">
          <InputNumber />
        </Form.Item>
      </Form>
    </Create>
  );
};
