import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
// import {
//   Create,
//   Form,
//   Input,
//   IResourceComponentsProps,
//   Select,
//   useForm,
//   useSelect,
// } from "@pankod/refine";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IAssociation } from "../../interfaces";

export const AssociationCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IAssociation>();

  const { selectProps } = useSelect({
    resource: "pricing-schemes",
    optionLabel: "name",
    optionValue: "id",
    sort: [{ field: "name", order: "asc" }],
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        {/* <Form.Item label="Locale" name="locale">
          <Radio.Group onChange={(e) => setLocale(e.target.value)}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="de">Deutsch</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          wrapperCol={{ span: 14 }}
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Information"
          name="information"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={"write"}
            toolbarCommands={[]}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Pricing Schemes"
          name="pricing_scheme"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
      </Form>
    </Create>
  );
};
