import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, Input, Switch, useForm } from "@pankod/refine-antd";
import React from "react";

import { IExtractionMethod } from "../../interfaces";
const { TextArea } = Input;

export const ExtractionMethodEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IExtractionMethod>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        // onFinish={(values: any) => {
        //   return formProps.onFinish?.({
        //     ...values,
        //     amplification_method: values.amplification_method?.data?.id,
        //     extraction_method: values.extraction_method?.data?.id,
        //     visualization_method: values.visualization_method?.data?.id,
        //   });
        // }}
      >
        <Form.Item wrapperCol={{ span: 8 }} label="Name" name="name">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Symbol" name="symbol">
          <Input />
        </Form.Item>
        <Form.Item label="Enabled" name="enabled" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Edit>
  );
};
