import {
  IResourceComponentsProps,
  useCreate,
  useUpdate,
} from "@pankod/refine-core";
import {
  DatePicker,
  Divider,
  Edit,
  Form,
  Icons,
  Input,
  Modal,
  Select,
  Table,
  useForm,
  useModal,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import React, { useState } from "react";

import {
  IAnalysis,
  ILabMutationResult,
  ISample,
  ISamples,
} from "../../interfaces";
import dayjs from "dayjs";
import RouterProvider from "@pankod/refine-react-router";

const { CloseCircleOutlined, CheckCircleOutlined } = Icons;
const Link = RouterProvider.Link;
const { TextArea } = Input;

export const SamplesEdit: React.FC<IResourceComponentsProps> = () => {
  const [notes, setNotes] = useState("");

  const { formProps, saveButtonProps, queryResult } = useForm<ISample>({
    metaData: {
      populate: [
        "order",
        "lab",
        "subspecie",
        // "analyses.extraction_methodz",
        // "analyses.amplification_method",
      ],
    },
    redirect: false,
  });

  const { selectProps: subspeciesSelect } = useSelect<IAnalysis>({
    resource: "subspecie/specie",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.subspecie?.data?.id,
    // sort: [{ field: "company_name", order: "asc" }],
  });

  const { tableProps: AnalysisList } = useTable<ISamples>({
    resource: "analyses",
    metaData: {
      populate: [
        "extraction_method",
        "amplification_method",
        "visualization_method",
      ],
    },
    permanentFilter: [
      {
        field: "sample",
        operator: "eq",
        value: queryResult?.data?.data?.id,
      },
    ],
  });

  const { mutate: mutateCreateLab } = useCreate<ILabMutationResult>();
  const { mutate: mutateUpdateSample } = useUpdate<ISamples>();
  const { mutate: mutateUpdateLab } = useUpdate<ILabMutationResult>();

  const {
    modalProps: modalLabMessagesProps,
    show: labShow,
    close: labClose,
  } = useModal();

  const messageOkHandler = () => {
    if (!queryResult || !queryResult.data) return;
    const id = queryResult.data.data.id.toString();
    const labId = queryResult?.data?.data?.lab?.data?.id;
    if (labId) {
      mutateUpdateLab(
        {
          resource: "labs",
          id: labId,
          values: {
            note: notes,
          },
        },
        {
          onSuccess: () => {
            queryResult.refetch();
          },
        }
      );
    } else {
      mutateCreateLab(
        {
          resource: "labs",
          values: {
            note: notes,
          },
        },
        {
          onSuccess: (data) => {
            mutateUpdateSample({
              resource: "samples",
              id: id,
              values: {
                lab: data.data.data.id,
              },
            });
          },
        }
      );
    }
    labClose();
  };

  const messageCancelHandler = () => {
    labClose();
  };

  const { Option } = Select;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            subspecie: values.subspecie?.data?.id,
          });
        }}
      >
        <Divider orientation="left">Order</Divider>
        <Form.Item wrapperCol={{ span: 8 }} label="Lab">
          {queryResult?.data?.data?.lab?.data?.id ? (
            queryResult?.data?.data?.lab?.data?.id
          ) : (
            <Link to="#" onClick={labShow}>
              Create
            </Link>
          )}
        </Form.Item>
        {queryResult?.data?.data?.lab?.data?.id && (
          <Form.Item wrapperCol={{ span: 8 }} label="Notes">
            <>
              <div>{queryResult?.data?.data?.lab?.data?.attributes?.note}</div>
              <Link
                to="#"
                onClick={() => {
                  if (queryResult?.data?.data?.lab?.data?.attributes?.note) {
                    setNotes(
                      queryResult?.data?.data?.lab?.data?.attributes?.note
                    );
                  }
                  labShow();
                }}
              >
                Edit
              </Link>
            </>
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ span: 8 }} label="Order">
          #{queryResult?.data?.data?.order?.data?.id}
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Identifier"
          name="identifier"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Sub Specie"
          name={["subspecie", "data", "id"]}
        >
          <Select {...subspeciesSelect} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Other species"
          name="other_species"
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Mutation" name="mutation">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Standard" name="standard">
          <Select>
            <Option key={""}>Normal sample</Option>
            <Option key={"M"}>Male Standard</Option>
            <Option key={"F"}>Female Standard</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Voucher" name="voucher">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Sample status"
          name="sample_state"
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Sample problem"
          name="sample_problem"
        >
          <Select>
            <Option value="">No problem</Option>
            <Option value="M">Missing</Option>
            <Option value="R">Failure risk</Option>
            <Option value="I">Not suitable</Option>
            <Option value="A">Canceled</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Owner" name="owner">
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Date of Birth"
          name="date_of_birth"
          getValueProps={(value) => ({
            value: value ? dayjs(value) : "",
          })}
        >
          <DatePicker />
        </Form.Item>
        <Divider orientation="left">Analysis</Divider>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Table {...AnalysisList} rowKey="id">
            <Table.Column dataIndex="id" title="ID" />
            <Table.Column dataIndex="analysis_type" title="Analysis type" />
            <Table.Column
              dataIndex={["extraction_method", "data", "attributes", "name"]}
              title="Extraction method"
            />
            <Table.Column
              dataIndex={["amplification_method", "data", "attributes", "name"]}
              title="Amplification method"
            />
            <Table.Column
              dataIndex={["visualization_method", "data", "attributes", "name"]}
              title="Visualization method"
            />
            <Table.Column dataIndex="notes_result" title="Notes result" />
            <Table.Column
              dataIndex="result"
              title="Result"
              render={(value) => {
                switch (value) {
                  case "M":
                    return "Maschio";
                  case "F":
                    return "Femmina";
                  case "POS":
                    return "Positivo";
                  case "NEG":
                    return "Negativo";
                  case "FAIL":
                    return "Inconclusivo";
                  default:
                    return "";
                }
              }}
            />
            <Table.Column
              dataIndex="refundable"
              title="Refundable"
              render={(value) => {
                return value ? (
                  <CheckCircleOutlined
                    style={{ fontSize: "16px", color: "#52c41a" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ fontSize: "16px", color: "#eb2f96" }}
                  />
                );
              }}
            />
            <Table.Column
              dataIndex="respect_times"
              title="Respect times"
              render={(value) => {
                return value ? (
                  <CheckCircleOutlined
                    style={{ fontSize: "16px", color: "#52c41a" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ fontSize: "16px", color: "#eb2f96" }}
                  />
                );
              }}
            />
            <Table.Column dataIndex="price" title="Price" />
            <Table.Column
              dataIndex="standard_analysis"
              title="Standard analysis"
              render={(value) => {
                return value ? (
                  <CheckCircleOutlined
                    style={{ fontSize: "16px", color: "#52c41a" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ fontSize: "16px", color: "#eb2f96" }}
                  />
                );
              }}
            />
            <Table.Column
              dataIndex="counted_successful"
              title="Counted successful"
              render={(value) => {
                return value ? (
                  <CheckCircleOutlined
                    style={{ fontSize: "16px", color: "#52c41a" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ fontSize: "16px", color: "#eb2f96" }}
                  />
                );
              }}
            />
            <Table.Column
              dataIndex="certificate_paper"
              title="Certificate paper"
              render={(value) => {
                return value ? (
                  <CheckCircleOutlined
                    style={{ fontSize: "16px", color: "#52c41a" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ fontSize: "16px", color: "#eb2f96" }}
                  />
                );
              }}
            />
            <Table.Column
              dataIndex="certificate_digital"
              title="Certificate digital"
              render={(value) => {
                return value ? (
                  <CheckCircleOutlined
                    style={{ fontSize: "16px", color: "#52c41a" }}
                  />
                ) : (
                  <CloseCircleOutlined
                    style={{ fontSize: "16px", color: "#eb2f96" }}
                  />
                );
              }}
            />
          </Table>
        </Form.Item>
      </Form>
      <Modal
        onOk={messageOkHandler}
        onCancel={messageCancelHandler}
        {...modalLabMessagesProps}
        title={"Lab"}
      >
        {queryResult?.data?.data?.lab?.data?.id && (
          <>
            <div>Lab ID</div>
            <div style={{ marginBottom: 10 }}>
              {queryResult?.data?.data?.lab?.data?.id}
            </div>
          </>
        )}
        <div>Notes</div>
        <div>
          <TextArea
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
        </div>
      </Modal>
    </Edit>
  );
};
