import { IResourceComponentsProps, useUpdate } from "@pankod/refine-core";
import { Icons, List, Popconfirm, Table, useTable } from "@pankod/refine-antd";
import RouterProvider from "@pankod/refine-react-router";
import { IOrder } from "../../interfaces";
// import { WEB_URL, TOKEN_KEY } from "../../constants";

export const CertificatesList: React.FC<IResourceComponentsProps> = () => {
  const {
    tableProps: certificates,
    tableQueryResult: certificatesTableQueryResult,
  } = useTable<IOrder>({
    resource: "analyses",
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "dispatched_certificates",
        operator: "null",
        value: "",
      },
      {
        field: "certificate_digital",
        operator: "eq",
        value: "true",
      },
      {
        field: "result",
        operator: "ne",
        value: "",
      },
    ],
    metaData: {
      populate: ["sample.order.client", "sample.subspecie.specie"],
    },
  });

  const {
    tableProps: certificatesShipped,
    tableQueryResult: certificatesShippedTableQueryResult,
  } = useTable<IOrder>({
    resource: "analyses",
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    permanentFilter: [
      {
        field: "dispatched_certificates",
        operator: "eq",
        value: "true",
      },
      {
        field: "certificate_digital",
        operator: "eq",
        value: "true",
      },
      {
        field: "result",
        operator: "ne",
        value: "",
      },
    ],
    initialPageSize: 20,
    metaData: {
      populate: ["sample.order.client", "sample.subspecie.specie"],
    },
  });

  const Link = RouterProvider.Link;
  const { CheckSquareOutlined } = Icons;

  const { mutate, isLoading } = useUpdate();

  return (
    <>
      <List title="Ordini da Attestare">
        <Table {...certificates} rowKey="id">
          <Table.Column
            dataIndex={["sample", "data", "attributes", "order", "data", "id"]}
            title="Nº ORD"
          />
          <Table.Column
            dataIndex={[
              "sample",
              "data",
              "attributes",
              "order",
              "data",
              "attributes",
              "client",
              "data",
              "attributes",
              "company_name",
            ]}
            title="Nome Cognome Ragione Sociale"
          />
          <Table.Column
            dataIndex={[
              "sample",
              "data",
              "attributes",
              "order",
              "data",
              "attributes",
              "client",
              "data",
              "attributes",
            ]}
            title="INDIRIZZO"
            render={(value) => {
              if (value) {
                return `${value.city} ${value.address} ${value.address_number} ${value.province} ${value.zip_code} `;
              }
              return "";
            }}
          />
          <Table.Column
            dataIndex={[
              "sample",
              "data",
              "attributes",
              "order",
              "data",
              "attributes",
              "client",
              "data",
              "attributes",
            ]}
            title="EMAIL TELEFONO"
            render={(value) => {
              if (value) {
                return (
                  <div>
                    <div>{value.email}</div>
                    <div>{value.phone}</div>
                    <div>{value.secondary_phone}</div>
                  </div>
                );
              }
              return "";
            }}
          />
          <Table.Column
            dataIndex={["sample", "data", "attributes", "identifier"]}
            title="IDENTIFICATIVO"
          />
          <Table.Column
            dataIndex={""}
            title="SPECIE"
            render={(value) => {
              if (value.sample.data.attributes.subspecie.data) {
                return `${
                  value?.sample?.data?.attributes?.subspecie?.data?.attributes
                    ?.specie?.data?.attributes?.name
                }${
                  value.sample.data.attributes.subspecie.data.attributes
                    .name !== ""
                    ? ` - ${value.sample.data.attributes.subspecie.data.attributes.name}`
                    : ""
                }`;
              } else {
                return value.sample.data.attributes.other_species;
              }
            }}
          />
          <Table.Column dataIndex={"analysis_type"} title="ANALISI" />
          <Table.Column
            dataIndex={["result"]}
            title="ESITO"
            render={(value) => {
              switch (value) {
                case "M":
                  return "Maschio";
                case "F":
                  return "Femmina";
                default:
                  return "";
              }
            }}
          />
          <Table.Column
            dataIndex={"id"}
            title="LINK"
            render={(value) => {
              return (
                <a
                  href={`/certificate/${value}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  mostra
                </a>
              );
            }}
          />
          <Table.Column
            dataIndex={"id"}
            title=""
            render={(value) => {
              return (
                <div style={{ width: 60 }}>
                  <Popconfirm
                    key="delete"
                    okText={"Accettare"}
                    cancelText={"Cancella"}
                    okType="danger"
                    title={"Sei sicuro?"}
                    okButtonProps={{ disabled: isLoading }}
                    onConfirm={(): void => {
                      mutate(
                        {
                          resource: "analyses",
                          id: value,
                          values: {
                            dispatched_certificates: true,
                            certificate_shipment_date: new Date(),
                          },
                        },
                        {
                          onSuccess: () => {
                            certificatesTableQueryResult.refetch();
                            certificatesShippedTableQueryResult.refetch();
                          },
                        }
                      );
                    }}
                  >
                    <Link to="#">
                      <CheckSquareOutlined
                        style={{ fontSize: "32px", color: "#52c41a" }}
                      />
                    </Link>
                  </Popconfirm>
                </div>
              );
            }}
          />
        </Table>
      </List>
      <List title="Ordini Attestati e Spediti">
        <Table {...certificatesShipped} rowKey="id" pagination={false}>
          <Table.Column
            dataIndex={["sample", "data", "attributes", "order", "data", "id"]}
            title="Nº ORD"
          />
          <Table.Column
            dataIndex={[
              "sample",
              "data",
              "attributes",
              "order",
              "data",
              "attributes",
              "client",
              "data",
              "attributes",
              "company_name",
            ]}
            title="Nome Cognome Ragione Sociale"
          />
          <Table.Column
            dataIndex={[
              "sample",
              "data",
              "attributes",
              "order",
              "data",
              "attributes",
              "client",
              "data",
              "attributes",
            ]}
            title="INDIRIZZO"
            render={(value) => {
              if (value) {
                return `${value.city} ${value.address} ${value.address_number} ${value.province} ${value.zip_code} `;
              }
              return "";
            }}
          />
          <Table.Column
            dataIndex={[
              "sample",
              "data",
              "attributes",
              "order",
              "data",
              "attributes",
              "client",
              "data",
              "attributes",
            ]}
            title="EMAIL TELEFONO"
            render={(value) => {
              if (value) {
                return (
                  <div>
                    <div>{value.email}</div>
                    <div>{value.phone}</div>
                    <div>{value.secondary_phone}</div>
                  </div>
                );
              }
              return "";
            }}
          />
          <Table.Column
            dataIndex={"certificate_shipment_date"}
            title="Data Spedizione"
            render={(value) => {
              var date = new Date(value);
              return date.toLocaleDateString("it");
            }}
          />
          <Table.Column
            dataIndex={["sample", "data", "attributes", "identifier"]}
            title="IDENTIFICATIVO"
          />
          <Table.Column
            dataIndex={""}
            title="SPECIE"
            render={(value) => {
              if (value.sample.data.attributes.subspecie.data) {
                return `${
                  value?.sample?.data?.attributes?.subspecie?.data?.attributes
                    ?.specie?.data?.attributes?.name
                }${
                  value.sample.data.attributes.subspecie.data.attributes
                    .name !== ""
                    ? ` - ${value.sample.data.attributes.subspecie.data.attributes.name}`
                    : ""
                }`;
              } else {
                return value.sample.data.attributes.other_species;
              }
            }}
          />
          <Table.Column dataIndex={"analysis_type"} title="ANALISI" />
          <Table.Column
            dataIndex={["result"]}
            title="ESITO"
            render={(value) => {
              switch (value) {
                case "M":
                  return "Maschio";
                case "F":
                  return "Femmina";
                default:
                  return "";
              }
            }}
          />
          <Table.Column
            dataIndex={"id"}
            title="LINK"
            render={(value) => {
              return (
                <a
                  href={`/certificate/${value}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  mostra
                </a>
              );
            }}
          />
        </Table>
      </List>
    </>
  );
};
