import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  Button,
  Col,
  Collapse,
  DeleteButton,
  Form,
  FormProps,
  Input,
  List,
  Row,
  Select,
  Space,
  Table,
  useSelect,
  useTable,
  Icons,
} from "@pankod/refine-antd";
import { IClient, IOrder, IOrderFilterVariables } from "../../interfaces";
import { openInNewTab } from "../../functions";

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { selectProps: clientsSelectProps } = useSelect<IClient>({
    resource: "clients",
    optionLabel: "company_name",
    sort: [{ field: "company_name", order: "asc" }],
  });

  const { Panel } = Collapse;
  const { Option } = Select;

  return (
    <Collapse style={{ marginBottom: 10 }} defaultActiveKey={["1"]}>
      <Panel
        header={
          <>
            <Icons.FilterOutlined style={{ marginRight: 10 }} />
            Filters
          </>
        }
        key="1"
        showArrow={false}
      >
        <Form layout="vertical" {...formProps}>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Search" name="q">
                <Input
                  placeholder="ID, Prices, etc."
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Clients" name="client">
                <Select
                  {...clientsSelectProps}
                  allowClear
                  placeholder="Search Client"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Status" name="status">
                <Select
                  placeholder="Search Status"
                  style={{ width: "100%" }}
                  allowClear
                >
                  <Option key={"RNS_FEM"}>
                    Awaiting RNS Managing by FEM2 Ambiente
                  </Option>
                  <Option key={"RNS_CLIENTE"}>
                    Awaiting RNS Managing by Customer
                  </Option>
                  <Option key={"PAGAMENTO"}>Waiting for Payment</Option>
                  <Option key={"MATERIALE"}>Waiting for Sample</Option>
                  <Option key={"ANALISI_PARZIALE"}>
                    Analyzing (missing samples)
                  </Option>
                  <Option key={"ANALISI"}>Analyzing</Option>
                  <Option key={"CARTACEI"}>
                    Awaiting shipment of Paper Reports
                  </Option>
                  <Option key={"FATTURAZIONE"}>Waiting for Invoice</Option>
                  <Option key={"CHIUSO"}>Closed</Option>
                  <Option key={"ANNULLATO"}>Canceled</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Payment " name="payment">
                <Select
                  placeholder="Search Payment"
                  style={{ width: "100%" }}
                  allowClear
                >
                  <Option key="PPAL">PayPal</Option>
                  <Option key="BONB">Bank Trasfer</Option>
                  <Option key="BOLP">Bollettino postale</Option>
                  <Option key="CARTA">Credit Card</Option>
                  <Option key="CREDITO">FEM Credit</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

export const OrdersList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IOrder,
    HttpError,
    IOrderFilterVariables
  >({
    permanentSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["client", "samples.lab"],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, client, status, payment } = params;

      filters.push(
        {
          field: "_q",
          operator: "eq",
          value: q,
        },
        {
          field: "client.id",
          operator: "eq",
          value: client,
        },
        {
          field: "status",
          operator: "eq",
          value: status,
        },
        {
          field: "payment_method",
          operator: "eq",
          value: payment,
        }
      );

      return filters;
    },
  });

  // const Link = RouterProvider.Link;
  return (
    <List>
      <Filter formProps={searchFormProps} />
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="createdAt" title="Created at" />
        <Table.Column
          dataIndex={["client", "data", "attributes", "company_name"]}
          title="Client"
        />
        <Table.Column dataIndex="amount" title="Billable amount" />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value) => {
            switch (value) {
              case "RNS_FEM":
                return "Awaiting RNS Managing by FEM2 Ambiente";
              case "RNS_CLIENTE":
                return "Awaiting RNS Managing by Customer";
              case "PAGAMENTO":
                return "Waiting for Payment";
              case "MATERIALE":
                return "Waiting for Sample";
              case "ANALISI_PARZIALE":
                return "Analyzing (missing samples)";
              case "ANALISI":
                return "Analyzing";
              case "CARTACEI":
                return "Awaiting shipment of Paper Reports";
              case "FATTURAZIONE":
                return "Waiting for Invoice";
              case "CHIUSO":
                return "Closed";
              case "ANNULLATO":
                return "Canceled";
              default:
                return "";
            }
          }}
        />
        <Table.Column
          dataIndex={["samples", "data"]}
          title="Missing analysis"
          render={(value) => {
            return value.filter((sample: any) => {
              return sample.attributes.lab.data === null;
            }).length;
          }}
        />
        <Table.Column
          title="Certificate shipment date"
          dataIndex="certificate_shipment_date"
          render={(value) => {
            if (!value) return "(Nada)";
            return value;
          }}
        />
        <Table.Column
          dataIndex="payment_method"
          title="Payment method"
          render={(value) => {
            switch (value) {
              case "PPAL":
                return "PayPal";
              case "BONB":
                return "Bank Trasfer";
              case "BOLP":
                return "Bollettino postale";
              case "CARTA":
                return "Credit Card";
              case "CREDITO":
                return "FEM Credit";
              default:
                return "";
            }
          }}
        />

        {/* <Table.Column
          dataIndex="id"
          title="Samples link"
          render={(value) => {
            return <Link to={"#"}>Link to all samples</Link>;
          }}
        /> */}
        <Table.Column<IOrder>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Button
                size="small"
                onClick={() => {
                  openInNewTab(`/orders/edit/${record.id}`);
                }}
                className={"ant-btn-icon-only"}
              >
                <Icons.EditOutlined />
              </Button>
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
