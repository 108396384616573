import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  Icons,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { IBuyCredit } from "../../interfaces";

const { CloseCircleOutlined, CheckCircleOutlined } = Icons;

export const BuyCreditList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IBuyCredit>({
    permanentSorter: [
      {
        field: "updatedAt",
        order: "desc",
      },
    ],
    metaData: {
      populate: ["client"],
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column
          dataIndex={["client", "data", "attributes", "company_name"]}
          title="Client"
        />
        <Table.Column dataIndex="amount" title="Amount" />
        <Table.Column dataIndex="credit" title="Credit" />
        <Table.Column
          dataIndex="paid"
          title="Paid"
          render={(value) => {
            return value ? (
              <CheckCircleOutlined
                style={{ fontSize: "16px", color: "#52c41a" }}
              />
            ) : (
              <CloseCircleOutlined
                style={{ fontSize: "16px", color: "#eb2f96" }}
              />
            );
          }}
        />
        <Table.Column
          dataIndex={"payment_method"}
          title="Payment method"
          render={(value) => {
            switch (value) {
              case "PPAL":
                return "PayPal";
              case "BONB":
                return "Bank Trasfer";
              case "BOLP":
                return "Bollettino postale";
              case "CARTA":
                return "Credit Card";
              case "CREDITO":
                return "FEM Credit";
              default:
                return "";
            }
          }}
        />
        <Table.Column<IBuyCredit>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
