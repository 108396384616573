import {
  IResourceComponentsProps,
  useCreateMany,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import {
  Button,
  DeleteButton,
  Divider,
  Edit,
  Form,
  Icons,
  Input,
  ListButton,
  Modal,
  NumberField,
  Popconfirm,
  RefreshButton,
  Select,
  Space,
  Switch,
  Table,
  useForm,
  useModal,
  useSelect,
  useTable,
} from "@pankod/refine-antd";

import {
  IClient,
  IEmailTemplate,
  ILabMutationResult,
  IOrder,
  ISamples,
} from "../../interfaces";
import { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import axios from "axios";
import { API_URL, TOKEN_KEY } from "../../constants";

const { TextArea } = Input;
const { CloseCircleOutlined, CheckCircleOutlined, EyeOutlined } = Icons;

export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
  const axiosInstance = axios.create();
  const { formProps, saveButtonProps, queryResult } = useForm<IOrder>({
    metaData: {
      populate: ["client", "samples.lab"],
    },
    redirect: false,
  });

  const [isEditorLoad, setIsEditorLoad] = useState(false);
  const [templateDesign, setTemplateDesign] = useState<IEmailTemplate | null>(
    null
  );

  const { selectProps: clientSelect } = useSelect<IClient>({
    resource: "clients",
    optionLabel: "company_name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.client?.data?.id,
    sort: [{ field: "company_name", order: "asc" }],
  });

  const { tableProps: SamplesList } = useTable<ISamples>({
    resource: "samples",
    metaData: {
      populate: ["order.client", "lab", "subspecie.specie", "analyses"],
    },
    permanentFilter: [
      {
        field: "order",
        operator: "eq",
        value: queryResult?.data?.data?.id,
      },
    ],
    permanentSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
  });

  const { selectProps: templatesSelect } = useSelect({
    resource: "email-templates",
    optionLabel: "name",
    optionValue: "id",
    sort: [{ field: "id", order: "asc" }],
  });

  const [paid, setPaid] = useState<boolean>();
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [registerPayment, setRegisterPayment] = useState("");

  useEffect(() => {
    if (!queryResult?.data?.data || paid != null || paymentMethod != null)
      return;
    setPaid(queryResult?.data?.data?.paid);
    setPaymentMethod(queryResult?.data?.data?.payment_method);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult]);

  const { Option } = Select;
  const { edit } = useNavigation();

  const {
    modalProps: modalMessagesProps,
    show: messagesShow,
    close: messagesClose,
  } = useModal();

  const {
    modalProps: modalregisterPaymentProps,
    show: registerPaymentShow,
    close: registerPaymentClose,
  } = useModal();

  const {
    modalProps: modalCancelOrderProps,
    show: cancelOrderShow,
    close: cancelOrderClose,
  } = useModal();

  const { mutate } = useUpdate<IOrder>();
  const { isLoading } = useUpdate<IOrder>();
  const { mutate: mutateCreateLab } = useCreateMany<ILabMutationResult>();

  const messageOkHandler = () => {
    messagesClose();
  };

  const messageCancelHandler = () => {
    messagesClose();
  };

  const registerPaymentOk = () => {
    if (!queryResult || !queryResult.data) return;
    const id = queryResult.data.data.id.toString();
    mutate(
      {
        resource: "orders",
        id: id,
        values: {
          status: "MATERIALE",
          payment_details: registerPayment,
          paid: true,
        },
      },
      {
        onSuccess: () => {
          setRegisterPayment("");
          setPaid(true);
        },
      }
    );
  };

  const registerPaymentOkHandler = () => {
    registerPaymentClose();
    registerPaymentOk();
  };

  const registerPaymentCancelHandler = () => {
    registerPaymentClose();
    setRegisterPayment("");
  };

  const cancelOrderOK = () => {
    if (!queryResult || !queryResult.data) return;
    const id = queryResult.data.data.id.toString();
    mutate({
      resource: "orders",
      id: id,
      values: { status: "ANNULLATO" },
    });
  };

  const cancelOrderOkHandler = () => {
    cancelOrderClose();
    cancelOrderOK();
  };

  const cancelOrderCancelHandler = () => {
    cancelOrderClose();
  };

  const emailEditorRef = useRef<any>(null);

  const onLoad = () => {
    setIsEditorLoad(true);
  };

  const getTemplate = async (value: any) => {
    setIsEditorLoad(true);
    const idTemplate = value;
    const token = localStorage.getItem(TOKEN_KEY);
    axiosInstance.defaults.headers = {
      Authorization: `Bearer ${token}`,
    };
    // setTemplateDesign();
    const { data } = await axiosInstance.get(
      `${API_URL}/api/email-templates/${idTemplate}`
    );
    setTemplateDesign(
      Object.assign({ id: data.data.id }, { ...data.data.attributes })
    );
  };

  useEffect(() => {
    if (!isEditorLoad) return;
    if (!templateDesign) return;
    const templateJson = JSON.parse(templateDesign?.design || "");
    emailEditorRef.current.editor.loadDesign(templateJson);
    setIsEditorLoad(false);
  }, [isEditorLoad, templateDesign]);

  function sortByKey(array: any) {
    return array.sort(function (a: any, b: any) {
      var keyA = a.data.id,
        keyB = b.data.id;
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  const insertLabId = (data: any, ids: any) => {
    data.forEach((item: any, key: number) => {
      mutate({
        resource: "samples",
        id: ids[key],
        values: {
          lab: item.data.id,
        },
      });
    });
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: (
          <Space>
            <ListButton />
            <Button type="default" onClick={messagesShow}>
              Messages
            </Button>
            <Button type="default" onClick={registerPaymentShow}>
              Register Payment
            </Button>
            <Button type="default" onClick={cancelOrderShow}>
              Cancel Order
            </Button>
            <RefreshButton />
          </Space>
        ),
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            client: values.client?.data.id,
            paid: paid,
            payment_method: paymentMethod,
          });
        }}
      >
        <Divider orientation="left">Order</Divider>
        <Form.Item wrapperCol={{ span: 8 }} label="Status" name="status">
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"RNS_FEM"}>
              Awaiting RNS Managing by FEM2 Ambiente
            </Option>
            <Option key={"RNS_CLIENTE"}>
              Awaiting RNS Managing by Customer
            </Option>
            <Option key={"PAGAMENTO"}>Waiting for Payment</Option>
            <Option key={"MATERIALE"}>Waiting for Sample</Option>
            <Option key={"ANALISI_PARZIALE"}>
              Analyzing (missing samples)
            </Option>
            <Option key={"ANALISI"}>Analyzing</Option>
            <Option key={"CARTACEI"}>Awaiting shipment of Paper Reports</Option>
            <Option key={"FATTURAZIONE"}>Waiting for Invoice</Option>
            <Option key={"CHIUSO"}>Closed</Option>
            <Option key={"ANNULLATO"}>Canceled</Option>
          </Select>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Billable amount">
          {queryResult?.data?.data?.amount || "0.00"}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Services to be paid">
          {queryResult?.data?.data?.services_to_pay}
          <span style={{ marginLeft: 40, marginRight: 10 }}>
            Crediti consumati:
          </span>
          {queryResult?.data?.data?.credits_consumed || "0.00"}
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Client"
          name={["client", "data", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...clientSelect} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Note client">
          <div style={{ whiteSpace: "pre" }}>
            {queryResult?.data?.data?.customer_notes}
          </div>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Paid"
          name={"payment_method"}
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Switch
              checked={paid}
              onChange={(value) => {
                setPaid(value);
              }}
            />
            <span style={{ marginLeft: 10, marginRight: 10 }}>
              Payment method:
            </span>
            <div style={{ flex: 1, maxWidth: 200 }}>
              <Select
                defaultValue={paymentMethod}
                onChange={(value) => setPaymentMethod(value)}
              >
                <Option key={""}>---------</Option>
                <Option key="PPAL">PayPal</Option>
                <Option key="BONB">Bank Trasfer</Option>
                <Option key="BOLP">Bollettino postale</Option>
                <Option key="CARTA">Credit Card</Option>
                <Option key="CREDITO">FEM Credit</Option>
              </Select>
            </div>
          </div>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Missing analysis">
          {
            queryResult?.data?.data?.samples?.data.filter((sample: any) => {
              return sample.attributes.lab.data === null;
            }).length
          }
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Payment details"
          name="payment_details"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Notes admin"
          name="notes_admin"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Tax notes" name="tax_notes">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Order entered personal fem"
          name={"order_insert_personal_fem"}
        >
          {queryResult?.data?.data?.order_insert_personal_fem ? (
            <CheckCircleOutlined
              style={{ fontSize: "16px", color: "#52c41a" }}
            />
          ) : (
            <CloseCircleOutlined
              style={{ fontSize: "16px", color: "#eb2f96" }}
            />
          )}
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="">
          <Popconfirm
            key="assign-all"
            okText={"Assign"}
            cancelText={"Cancel"}
            okType="primary"
            title={"Are you sure?"}
            okButtonProps={{ disabled: isLoading }}
            onConfirm={(): void => {
              let entries: any[] = [];
              let ids: any[] = [];
              queryResult?.data?.data?.samples?.data.forEach((item) => {
                if (item?.attributes?.lab?.data == null) {
                  entries.push({
                    notes: "",
                  });
                  ids.push(item.id);
                }
              });

              // console.log("entries", entries);
              // console.log("ids", ids);

              if (entries.length) {
                mutateCreateLab(
                  {
                    resource: "labs",
                    values: entries,
                  },
                  {
                    onSuccess: (data) => {
                      insertLabId(sortByKey(data?.data), ids);
                    },
                  }
                );
              }

              // mutate(
              //     {
              //         id: id || "",
              //         resource: resourceName,
              //         mutationMode,
              //         successNotification,
              //         errorNotification,
              //         metaData,
              //         dataProviderName,
              //         invalidates,
              //     },
              //     {
              //         onSuccess: (value) => {
              //             onSuccess && onSuccess(value);
              //         },
              //     },
              // );
            }}
            // disabled={data?.can === false}
          >
            <Button size="small">Assign id to all samples</Button>
          </Popconfirm>
        </Form.Item>
        <Divider orientation="left">Samples</Divider>
        <Form.Item wrapperCol={{ span: 32 }}>
          <Table {...SamplesList} rowKey="id">
            {/* <Table.Column dataIndex="id" title="ID" /> */}
            <Table.Column dataIndex="identifier" title="Identifier" />
            <Table.Column dataIndex={["lab", "data", "id"]} title="Id lab" />
            <Table.Column dataIndex="sample_state" title="Sample state" />
            <Table.Column
              dataIndex=""
              title="Sample type"
              render={(values) => {
                return (
                  <div>
                    <div style={{ textAlign: "center" }}>
                      {values.standard === "M"
                        ? "Male Standard"
                        : values.standard === "F"
                        ? "Female Standard"
                        : "Campione Normale"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ marginRight: 10 }}>RNS:</span>
                      {values.other_species ? (
                        <CheckCircleOutlined
                          style={{ fontSize: "16px", color: "#52c41a" }}
                        />
                      ) : (
                        <CloseCircleOutlined
                          style={{ fontSize: "16px", color: "#eb2f96" }}
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            />
            <Table.Column
              dataIndex="subspecie"
              title="Sub specie"
              render={(value) => {
                if (!value?.data?.attributes?.specie) return "";
                return `${
                  value?.data?.attributes?.specie?.data?.attributes?.name
                }${
                  value?.data?.attributes?.name
                    ? ` - ${value?.data?.attributes?.name}`
                    : ""
                }`;
              }}
            />
            <Table.Column dataIndex="mutation" title="Mutation" />
            <Table.Column
              dataIndex=""
              title="Owner"
              render={(value) => {
                if (value.owner && value.owner !== "") {
                  return value.owner;
                }
                // console.log("value", value);
                return value?.order?.data?.attributes?.client?.data?.attributes
                  ?.company_name;
              }}
            />
            <Table.Column dataIndex="date_of_birth" title="Date of birth" />
            <Table.Column
              dataIndex={["analyses", "data"]}
              title="Type of analysis required"
              render={(value) => {
                return (
                  <div style={{ width: 150 }}>
                    <div style={{ fontWeight: "bold" }}>Analisi</div>
                    {value.map((item: any, key: string) => {
                      return (
                        <div key={key}>
                          {item.attributes.analysis_type}
                          {" - "}
                          <NumberField
                            value={item.attributes.price || 0}
                            options={{
                              notation: "standard",
                            }}
                          />{" "}
                          <span>€</span>
                        </div>
                      );
                    })}
                    <div style={{ fontWeight: "bold" }}>
                      Certificati digitali
                    </div>
                    {value
                      .filter((item: any) => {
                        return item.attributes.certificate_digital;
                      })
                      .map((item: any, key: string) => {
                        return (
                          <div key={key} style={{ width: 120 }}>
                            {item.attributes.analysis_type}
                            {" - "}
                            <span>2 €</span>
                          </div>
                        );
                      })}
                  </div>
                );
              }}
            />
            {/* <Table.Column dataIndex="voucher" title="Voucher" /> */}
            <Table.Column
              dataIndex="sample_problem"
              title="Sample problem"
              render={(value) => {
                switch (value) {
                  case "M":
                    return "Missing";
                  case "R":
                    return "Failure risk";
                  case "I":
                    return "Not suitable";
                  case "A":
                    return "Canceled";
                  default:
                    return "No problem";
                }
              }}
            />
            <Table.Column<ISamples>
              title="Actions"
              dataIndex="actions"
              render={(_text, record): React.ReactNode => {
                return (
                  <Space>
                    <Button
                      icon={<EyeOutlined />}
                      onClick={(): void => edit("samples", record.id)}
                      size="small"
                    />
                    <DeleteButton
                      size="small"
                      recordItemId={record.id}
                      hideText
                      resourceName="samples"
                    />
                  </Space>
                );
              }}
            />
          </Table>
        </Form.Item>
      </Form>
      <Modal
        onOk={messageOkHandler}
        onCancel={messageCancelHandler}
        {...modalMessagesProps}
        title={"Messages"}
        width={"100%"}
        centered
      >
        <div style={{ fontWeight: "bold" }}>Template</div>
        <div style={{ marginBottom: 10 }}>
          <Select
            {...templatesSelect}
            placeholder="Select..."
            style={{ width: "100%" }}
            onChange={(value) => {
              getTemplate(value);
            }}
          />
        </div>
        <div>
          <EmailEditor
            ref={emailEditorRef}
            projectId={1}
            onLoad={onLoad}
            options={{
              displayMode: "email",
              projectId: 1,
              customCSS: [
                `
              .blockbuilder-preferences, .blockbuilder-placeholder {
                font-family: "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                color: #234361;
              }

              .tab-content {
                background-color: rgb(250, 251, 255);
              }

              .icsSuz .blockbuilder-content-tool:hover:not(.disabled), .icsSuz .blockbuilder-classic-tool:hover:not(.disabled) {
                box-shadow: rgb(0 0 0 / 35%) 0px 1px 3px;
              }

              .blockbuilder-placeholder .blockbuilder-placeholder-empty {
                background: rgb(211, 245, 247);
                color: rgb(71, 77, 102);
              }

              .blockbuilder-layer.blockbuilder-layer-selected > .blockbuilder-layer-selector .blockbuilder-layer-drag, .blockbuilder-layer-controls .blockbuilder-layer-control, .cunCrG, .blockbuilder-layer-control, .blockbuilder-layer-controls .blockbuilder-layer-control:hover {
                background-color: rgb(51, 102, 255);
              }
            `,
              ],
            }}
            appearance={{
              panels: {
                tools: {
                  dock: "left",
                },
              },
            }}
          />
        </div>
      </Modal>
      <Modal
        onOk={registerPaymentOkHandler}
        onCancel={registerPaymentCancelHandler}
        {...modalregisterPaymentProps}
        title={"Register Payment"}
      >
        <div>Enter payment details:</div>
        <div>
          <TextArea
            rows={4}
            value={registerPayment}
            onChange={(e) => setRegisterPayment(e.currentTarget.value)}
          />
        </div>
      </Modal>
      <Modal
        onOk={cancelOrderOkHandler}
        onCancel={cancelOrderCancelHandler}
        {...modalCancelOrderProps}
        title={"Cancel order"}
      >
        Are you sure you want to cancel your order?
      </Modal>
    </Edit>
  );
};
