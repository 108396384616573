import { Create, Form, Input, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
// import {
//   Create,
//   Form,
//   Input,
//   IResourceComponentsProps,
//   useForm,
// } from "@pankod/refine";

import { ISpecie } from "../../interfaces";

export const SpecieCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<ISpecie>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          wrapperCol={{ span: 12 }}
          label="Order"
          name="order"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 12 }}
          label="Family"
          name="family"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 12 }}
          label="Specie"
          name={"name"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
