import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { IVisualizationMethod } from "../../interfaces";

export const VisualizationMethodList: React.FC<
  IResourceComponentsProps
> = () => {
  const { tableProps } = useTable<IVisualizationMethod>({
    permanentSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Visualization method" />
        <Table.Column<IVisualizationMethod>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
