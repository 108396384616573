import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
import React from "react";

import { IBuyCredit } from "../../interfaces";
const { TextArea } = Input;

export const BuyCreditEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IBuyCredit>({
    metaData: {
      populate: ["client", "package"],
    },
  });

  const { selectProps: clientSelect } = useSelect<IBuyCredit>({
    resource: "clients",
    optionLabel: "company_name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.client?.data?.id,
    sort: [{ field: "company_name", order: "asc" }],
  });

  const { Option } = Select;

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            client: values.client?.data?.id,
            package: queryResult?.data?.data?.package?.data?.id,
          });
        }}
      >
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Client"
          name={["client", "data", "id"]}
        >
          <Select {...clientSelect} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} name="amount" label="Amount">
          <InputNumber />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} name="credit" label="Credit">
          <InputNumber />
        </Form.Item>
        <Form.Item label="Paid" name="paid" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Accredited" name="accredited" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Payment details"
          name="payment_details"
        >
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Payment method"
          name="payment_method"
        >
          <Select>
            <Option key={""}>---------</Option>
            <Option key={"PPAL"}>PayPal</Option>
            <Option key={"BONB"}>Bollettino postale</Option>
            <Option key={"CARTA"}>Credit Card</Option>
            <Option key={"CREDITO"}>FEM Credit</Option>
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
