import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  Switch,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
// import {
//   Create,
//   Form,
//   Input,
//   IResourceComponentsProps,
//   Select,
//   Switch,
//   useForm,
//   useSelect,
// } from "@pankod/refine";

import { IAssociation, IAssociationMembership } from "../../interfaces";

export const AssociationMembershipCreate: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm<IAssociationMembership>();

  const { selectProps } = useSelect<IAssociation>({
    resource: "associations",
    optionLabel: "name",
    optionValue: "id",
    sort: [{ field: "name", order: "asc" }],
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            association: values.association?.data.id,
          });
        }}
      >
        {/* <Form.Item label="Locale" name="locale">
          <Radio.Group onChange={(e) => setLocale(e.target.value)}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="de">Deutsch</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Association"
          name={["association", "data", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 14 }}
          label="Company Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 14 }}
          label="Card"
          name="card"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Unofficial" name="unofficial" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Create>
  );
};
