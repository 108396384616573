import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, Form, Input, Switch, useForm } from "@pankod/refine-antd";
import React from "react";

import { IVisualizationMethod } from "../../interfaces";
const { TextArea } = Input;

export const VisualizationMethodEdit: React.FC<
  IResourceComponentsProps
> = () => {
  const { formProps, saveButtonProps } = useForm<IVisualizationMethod>();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item wrapperCol={{ span: 8 }} label="Name" name="name">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 8 }} label="Symbol" name="symbol">
          <Input />
        </Form.Item>
        <Form.Item label="Enabled" name="enabled" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Edit>
  );
};
