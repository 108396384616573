import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
// import {
//   // BooleanField,
//   DeleteButton,
//   EditButton,
//   IResourceComponentsProps,
//   List,
//   // ShowButton,
//   Space,
//   Table,
//   useTable,
// } from "@pankod/refine";
import { IAssociation } from "../../interfaces";

export const AssociationList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IAssociation>({
    metaData: {
      populate: ["pricing_scheme"],
    },
  });
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex={["pricing_scheme", "data", "attributes", "name"]}
          title="Pricing Scheme"
        />
        <Table.Column<IAssociation>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* <ShowButton size="small" hideText recordItemId={record.id} /> */}
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
