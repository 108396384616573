import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  List,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
// import {
//   DeleteButton,
//   EditButton,
//   IResourceComponentsProps,
//   List,
//   // ShowButton,
//   Space,
//   Table,
//   useTable,
// } from "@pankod/refine";
import { ISubSpecie } from "../../interfaces";

export const PricingSchemeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ISubSpecie>({
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column dataIndex={"name"} title="Name" />
        <Table.Column
          dataIndex={"discount_applied"}
          title="Discount_applied"
          render={(value) => {
            switch (value) {
              case "PACC":
                return "Pacchetto";

              case "CONV":
                return "Convenzione";

              default:
                return "";
            }
          }}
        />
        <Table.Column<ISubSpecie>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* <ShowButton size="small" hideText recordItemId={record.id} /> */}
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
