import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  useTable,
  Space,
  EditButton,
  DeleteButton,
  BooleanField,
  Icons,
  Collapse,
  Form,
  FormProps,
  Row,
  Col,
  Input,
  Button,
  DateField,
} from "@pankod/refine-antd";

import { IUser, IUserFilterVariables } from "../../interfaces";

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { Panel } = Collapse;

  return (
    <Collapse style={{ marginBottom: 10 }} defaultActiveKey={["1"]}>
      <Panel
        header={
          <>
            <Icons.FilterOutlined style={{ marginRight: 10 }} />
            Filters
          </>
        }
        key="1"
        showArrow={false}
      >
        <Form layout="vertical" {...formProps}>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Search" name="q">
                <Input
                  placeholder="ID, Username, Email, Full name, etc."
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IUser,
    HttpError,
    IUserFilterVariables
  >({
    permanentSorter: [
      {
        field: "username",
        order: "asc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q } = params;

      filters.push({
        field: "_q",
        operator: "eq",
        value: q,
      });

      return filters;
    },
  });

  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;

  return (
    <List>
      {/* <Form layout="inline" initialValues={{ locale }}>
        <Form.Item label="Locale" name="locale">
          <Radio.Group onChange={(e) => setLocale(e.target.value)}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="de">Deutsch</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form> */}
      <Filter formProps={searchFormProps} />
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column dataIndex="id" title="ID" /> */}
        <Table.Column dataIndex="username" title="Username" />
        <Table.Column dataIndex="email" title="Email" />
        <Table.Column dataIndex="full_name" title="Full name" />
        <Table.Column
          title="Staff"
          dataIndex={["role", "data", "attributes", "type"]}
          render={(value) => {
            return (
              <BooleanField
                value={value === "dashboard"}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column
          title="Created at"
          dataIndex={["createdAt"]}
          render={(value) => {
            if (!value) return "";
            return <DateField format="DD-MM-YYYY HH:mm" value={value} />;
          }}
          align="center"
        />
        <Table.Column
          title="Last login"
          dataIndex={["last_login"]}
          render={(value) => {
            if (!value) return "";
            return <DateField format="DD-MM-YYYY HH:mm" value={value} />;
          }}
          align="center"
        />
        {/* <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => <DateField value={value} format="LLL" />}
          sorter
        /> */}
        <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
