import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import { API_URL } from "../../constants";

import {
  BooleanField,
  DeleteButton,
  EditButton,
  Icons,
  List,
  Space,
  Table,
  useTable,
  ImageField,
  FormProps,
  Collapse,
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
} from "@pankod/refine-antd";

import { IClient, IClientFilterVariables } from "../../interfaces";

const Filter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  const { Panel } = Collapse;
  const { Option } = Select;

  return (
    <Collapse style={{ marginBottom: 10 }} defaultActiveKey={["1"]}>
      <Panel
        header={
          <>
            <Icons.FilterOutlined style={{ marginRight: 10 }} />
            Filters
          </>
        }
        key="1"
        showArrow={false}
      >
        <Form layout="vertical" {...formProps}>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Search" name="q">
                <Input
                  placeholder="ID, Company name, Tax ID, Phone, etc."
                  prefix={<Icons.SearchOutlined />}
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item label="Type" name="type">
                <Select
                  allowClear
                  placeholder="Search Type"
                  style={{ width: "100%" }}
                >
                  <Option key={"AMAT"}>Amatore</Option>
                  <Option key={"ALL"}>Allevatore</Option>
                  <Option key={"VET"}>Veterinario</Option>
                  <Option key={"NEG"}>Negozio</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className={"filtersCol"}>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
};

export const ClientList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IClient,
    HttpError,
    IClientFilterVariables
  >({
    metaData: {
      populate: [
        "pricing_scheme",
        "association_membership",
        "certificate_logo",
      ],
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, type } = params;

      filters.push(
        {
          field: "_q",
          operator: "eq",
          value: q,
        },
        {
          field: "type",
          operator: "eq",
          value: type,
        }
      );

      return filters;
    },
  });
  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;
  return (
    <List>
      <Filter formProps={searchFormProps} />
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" />
        <Table.Column
          dataIndex="type"
          title="Type"
          render={(value) => {
            switch (value) {
              case "AMAT":
                return "Amatore";
              case "ALL":
                return "Allevatore";
              case "VET":
                return "Veterinario";
              case "NEG":
                return "Negozio";
              default:
                return "";
            }
          }}
        />
        <Table.Column
          title="Vip"
          dataIndex="is_vip"
          render={(value) => {
            return (
              <BooleanField
                value={value}
                trueIcon={<CheckCircleOutlined />}
                falseIcon={<CloseCircleOutlined />}
                valueLabelTrue="Si"
                valueLabelFalse="No"
              />
            );
          }}
          align="center"
        />
        <Table.Column dataIndex="company_name" title="Company Name" />
        <Table.Column dataIndex="tax_id" title="Tax ID" />
        <Table.Column dataIndex="phone" title="Phone" />
        <Table.Column dataIndex="pec" title="Pec" />
        <Table.Column dataIndex="recipient_code" title="Recipient Code" />
        <Table.Column
          dataIndex="corporate_tax_code"
          title="Corporate Tax Code"
        />
        <Table.Column
          dataIndex={["pricing_scheme", "data", "attributes", "name"]}
          title="Pricing Scheme"
        />
        <Table.Column
          dataIndex={["association_membership", "data", "attributes", "name"]}
          title="Association Membership"
        />

        <Table.Column
          dataIndex={"certificate_logo"}
          align="center"
          title="Logo"
          render={(value) => {
            return value.data ? (
              <ImageField
                value={API_URL + value.data.attributes.url}
                alt={value.data.attributes.caption}
                title={value.data.attributes.caption}
                width={48}
                preview={{ mask: <></> }}
              />
            ) : (
              <span>---</span>
            );
          }}
        />

        <Table.Column<IClient>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton size="small" hideText recordItemId={record.id} />
              <DeleteButton size="small" hideText recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
