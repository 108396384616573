import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";
// import {
//   Edit,
//   Form,
//   Input,
//   IResourceComponentsProps,
//   Select,
//   useForm,
//   useSelect,
// } from "@pankod/refine";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";

import { IAssociation, IPricingScheme } from "../../interfaces";

export const AssociationEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IAssociation>({
    metaData: { populate: ["pricing_scheme"] },
  });

  const { selectProps } = useSelect<IPricingScheme>({
    resource: "pricing-schemes",
    optionLabel: "name",
    optionValue: "id",
    defaultValue: queryResult?.data?.data?.pricing_scheme?.data?.id,
    sort: [{ field: "name", order: "asc" }],
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          return formProps.onFinish?.({
            ...values,
            pricing_scheme: values.pricing_scheme?.data.id,
          });
        }}
      >
        {/* <Form.Item label="Locale" name="locale">
          <Radio.Group disabled>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="de">Deutsch</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          wrapperCol={{ span: 14 }}
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Information"
          name="information"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <ReactMde
            selectedTab={"write"}
            toolbarCommands={[]}
            generateMarkdownPreview={(markdown) =>
              Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
            }
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Pricing Schemes"
          name={["pricing_scheme", "data", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...selectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
