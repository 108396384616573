import { useState } from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Create, Form, Input, Switch, useForm } from "@pankod/refine-antd";
// import {
//   Create,
//   Form,
//   Input,
//   IResourceComponentsProps,
//   Switch,
//   useForm,
// } from "@pankod/refine";

import { IUser } from "../../interfaces";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IUser>();
  const [isStaff, isSetStaff] = useState(false);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(values: any) => {
          if (values.password === undefined) {
            delete values.password;
          }
          return formProps.onFinish?.({
            ...values,
            role: isStaff ? 3 : 1,
            provider: "local",
            confirmed: 1,
          });
        }}
      >
        {/* <Form.Item label="Locale" name="locale">
          <Radio.Group onChange={(e) => setLocale(e.target.value)}>
            <Radio.Button value="en">English</Radio.Button>
            <Radio.Button value="de">Deutsch</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Username"
          name="username"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Password"
          name="password"
          rules={[
            {
              required: true,
            },
            {
              min: 6,
            },
          ]}
        >
          <Input.Password placeholder="input password" />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 14 }}
          label="Full name"
          name="full_name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 8 }}
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Staff" name={"role"}>
          <Switch
            checked={isStaff}
            onChange={(value) => {
              isSetStaff(value);
            }}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
